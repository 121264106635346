import { Box, Button, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FilterInput } from "../../Components/Forms/FilterInputField";
import Logo from "../../Logo/Logo";
import { useAuth } from "../../SWR/EntityHooks/UseAuth";

export default function LoginIndex() {
  const { data, mutate } = useAuth();

  const [inputPassword, setInputPassword] = useState("");

  const onLogin = async () => {
    localStorage.setItem("key", inputPassword);
    await mutate();
  };

  useEffect(() => {
    if (data?.authenticated) {
      window.location.href = "/";
    }
  }, [data]);

  return (
    <Box
      height="100vh"
      width="100vw"
      bg="lightgray"
      display="flex"
      justifyContent="start"
      alignItems="center"
      flexDirection="column"
    >
      <Stack
        mt={"20vh"}
        p={8}
        boxShadow="xl"
        maxWidth="350px"
        borderRadius="10px"
        borderColor="black"
        borderWidth="2px"
        alignItems="center"
        justifyContent="center"
      >
        <Logo />
        <Stack width="full" mt={8}>
          <FilterInput
            defaultValue=""
            onChange={(e) => {
              setInputPassword(e);
            }}
            type="string"
            label="Password"
            name="Password"
            showLabel={false}
            placeholder="Password"
          />
          <Button width="full" onClick={onLogin}>
            Login
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
