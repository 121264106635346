import PageHeader from "../../Components/Typography/PageHeader";
import CustomersTable from "./CustomersTable";

export default function CustomersIndex() {
  return (
    <>
      <PageHeader
        title="Customers"
        subtitle="Hey! I think these people like you :)"
      />
      <CustomersTable />
    </>
  );
}
