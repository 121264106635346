import { Image } from "@chakra-ui/react";
import LogoImg from "./logo.png";

export default function Logo() {
  return (
    <Image
      src={LogoImg}
      alt="MacroMeals Logo"
      borderRadius="20px"
      height="100px"
      width="150px"
    />
  );
}
