export const ApiRoutes = {
  Pictures: {
    Delete: (id: number) => `/pictures/${id}`,
    UpdateAltText: (id: number) => `/pictures/${id}`,
  },
  Products: {
    Create: "/products",
    Delete: (id: number) => `/products/${id}`,
    Update: (id: number) => `/products/${id}`,
    AddProductPicture: (productId: number) => `/products/${productId}/pictures`,
    ProductConfigurationOptions: {
      Index: (productId: number) =>
        `/products/${productId}/product-configuration-options`,
      Update: (productId: number, productConfigurationOptionId: number) =>
        `/products/${productId}/product-configuration-options/${productConfigurationOptionId}`,
      Delete: (productId: number, productConfigurationOptionId: number) =>
        `/products/${productId}/product-configuration-options/${productConfigurationOptionId}`,
      Create: (productId: number, productConfigurationOptionId: number) =>
        `/products/${productId}/product-configuration-options/${productConfigurationOptionId}`,
    },
  },
  ProductCategories: {
    Create: "/product-categories",
    Update: (id: number) => `/product-categories/${id}`,
    Delete: (id: number) => `/product-categories/${id}`,
    Products: {
      Add: "/product-categories/products/add",
      Remove: "/product-categories/products/remove",
    },
  },
  MenuCycles: {
    Create: "/menu-cycles",
    Show: (id: number) => `/menu-cycles/${id}`,
    Update: (id: number) => `/menu-cycles/${id}`,
    Delete: (id: number) => `/menu-cycles/${id}`,
    Products: {
      Add: "/menu-cycles/add-product",
      Remove: "/menu-cycles/remove-product",
    },
  },
  ProductConfigurations: {
    Create: "/product-configurations",
    Update: (id: number) => `/product-configurations/${id}`,
    Delete: (id: number) => `/product-configurations/${id}`,
  },
  ProductConfigurationOptions: {
    Index: (productConfigurationId: number) =>
      `/product-configurations/${productConfigurationId}/product-configuration-options`,
    Update: (
      productConfigurationId: number,
      productConfigurationOptionId: number,
    ) =>
      `/product-configurations/${productConfigurationId}/product-configuration-options/${productConfigurationOptionId}`,
    Delete: (
      productConfigurationId: number,
      productConfigurationOptionId: number,
    ) =>
      `/product-configurations/${productConfigurationId}/product-configuration-options/${productConfigurationOptionId}`,
    Create: (productConfigurationId: number) =>
      `/product-configurations/${productConfigurationId}/product-configuration-options`,
  },
  Export: "/export/orders-and-deliveries",
};
