import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { CommonHookFormFieldProps } from "./Types";

export const HookTextarea = ({
  name,
  label,
  required,
  defaultValue,
  showPlaceHolder = false,
  showLabel = true,
  readonly = false,
}: CommonHookFormFieldProps) => {
  const methods = useFormContext();
  const error = methods.formState.errors[name];

  const refinedFieldError = () => {
    if (!error) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((error as any)[name]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (error as any)[name].message as string;
    }

    return undefined;
  };

  return (
    <FormControl isRequired={required} isInvalid={!!error}>
      {showLabel && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Textarea
        id={name}
        readOnly={readonly}
        borderColor="gray"
        defaultValue={defaultValue}
        {...methods.register(name)}
        {...(showPlaceHolder && {
          placeholder: label,
        })}
      />
      <FormErrorMessage>
        <Text>
          {error && error.message && error.message?.toString()}
          {error && !error.message && refinedFieldError()}
        </Text>
      </FormErrorMessage>
    </FormControl>
  );
};
