import {
  Avatar,
  Box,
  Button,
  HStack,
  Stack,
  StackDivider,
  Text,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaWindowClose } from "react-icons/fa";
import { BaseModal } from "../../Components/Modals/BaseModal";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";
import { useProductCategory } from "../../SWR/EntityHooks/UseProductCategory";
import { Product } from "../../SWR/Types/Product";
import { ProductCategory } from "../../SWR/Types/ProductCategory";
import AddProductToCategoryModalContent from "./AddProductToCategoryModalContent";

const ProductInProductCategory = ({
  product,
  isRemoveLoading,
  onRemove,
}: {
  product: Product;
  isRemoveLoading: boolean;
  onRemove: (product: Product) => void;
}) => {
  return (
    <Stack key={product.id} fontSize="sm" px="4" spacing="4" width="full">
      <Stack direction="row" justify="space-between" spacing="4" width="full">
        <HStack spacing="3" width="full">
          <Avatar
            src={
              product.pictures.length > 0
                ? product.pictures[0].full_picture_url
                : ""
            }
            boxSize="10"
          />
          <Box width="full">
            <HStack width="full" justifyContent="space-between">
              <Text fontWeight="medium" color="fg.emphasized">
                {product.name}
              </Text>
              <Button
                height="35px"
                width="50px"
                bg="red"
                isLoading={isRemoveLoading}
                onClick={() => onRemove(product)}
              >
                <Icon as={FaWindowClose} />
              </Button>
            </HStack>
          </Box>
        </HStack>
      </Stack>
    </Stack>
  );
};

type UpdateProductsInCategoryDrawerContentProps = {
  productCategory: ProductCategory;
};

export default function UpdateProductsInCategoryDrawerContent({
  productCategory,
}: UpdateProductsInCategoryDrawerContentProps) {
  const { data: fetchedProductCategory, mutate } = useProductCategory({
    id: productCategory?.id ?? 0,
  });

  const addNewProduct = useDisclosure();

  const [removeLoading, setRemoveLoading] = useState(false);

  const onRemove = (product: Product) => {
    if (!fetchedProductCategory) {
      return;
    }

    setRemoveLoading(true);
    apiClient
      .put(ApiRoutes.ProductCategories.Products.Remove, {
        product_id: product.id,
        product_category_id: fetchedProductCategory.id,
      })
      .then(() => {
        setRemoveLoading(false);
        mutate();
      });
  };

  return (
    <>
      <Box bg="bg.surface" py="4" width="full">
        <Stack divider={<StackDivider />} spacing="4" width="full">
          <Button onClick={addNewProduct.onOpen}>Add Product</Button>
          {fetchedProductCategory &&
            fetchedProductCategory.products.map((product) => (
              <ProductInProductCategory
                isRemoveLoading={removeLoading}
                onRemove={onRemove}
                product={product}
                key={product.id}
              />
            ))}
        </Stack>
      </Box>
      <BaseModal
        isOpen={addNewProduct.isOpen}
        header={null}
        onClose={addNewProduct.onClose}
        showCloseButton
      >
        <AddProductToCategoryModalContent
          category={fetchedProductCategory}
          onAdded={() => {
            mutate();
          }}
        />
      </BaseModal>
    </>
  );
}
