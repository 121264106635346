import {
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiEdit2, FiPlayCircle, FiTrash2 } from "react-icons/fi";
import { Pagination } from "../../Components/Pagination/pagination";
import { usePagination } from "../../Hooks/UsePagination";
import { Stack } from "@chakra-ui/react";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import { BaseDrawer } from "../../Components/Drawer/BaseDrawer";
import PageHeader from "../../Components/Typography/PageHeader";
import CreateNewProductCategoryDrawerContent from "./CreateNewProductCategoryDrawerContent";
import { useState } from "react";
import UpdateProductCategoryDrawerContent from "./UpdateProductCategoryDrawerContent";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";
import DeleteConfirmationModal from "../../Components/Modals/DeleteConfirmationModal";
import { ProductCategory } from "../../SWR/Types/ProductCategory";
import { useProductCategoriesIndex } from "../../SWR/EntityHooks/UseProductCategoriesIndex";
import UpdateProductsInCategoryDrawerContent from "./UpdateProductsInCategoryDrawerContent";

export const ProductCategoriesTable = () => {
  const { currentPage, handleNext, handlePrev } = usePagination();

  const { data, error, isLoading, mutate } = useProductCategoriesIndex({
    page: currentPage,
    perPage: 10,
  });

  const toast = useToast();

  const [selectedProductCategory, setSelectedProductCategory] = useState<
    ProductCategory | undefined
  >(undefined);

  const [selectedProductCategoryToDelete, setSelectedProductCategoryToDelete] =
    useState<ProductCategory | undefined>(undefined);

  const createNewProductCategory = useDisclosure();
  const updateProductCategory = useDisclosure();
  const updateProductCategoryProducts = useDisclosure();

  const onDelete = () => {
    apiClient
      .delete(
        ApiRoutes.ProductCategories.Delete(
          selectedProductCategoryToDelete?.id ?? 0,
        ),
      )
      .then(() => {
        setSelectedProductCategoryToDelete(undefined);
        toast({
          title: "ProductCategory deleted!",
          status: "success",
          duration: 2000,
        });
        mutate();
      });
  };

  const onSuccessfullyCreated = () => {
    createNewProductCategory.onClose();
    toast({
      title: "Created!",
      status: "success",
      duration: 2000,
    });
    mutate();
  };

  return (
    <>
      <Box
        bg="bg.surface"
        boxShadow={{ base: "none", md: "sm" }}
        borderRadius={{ base: "none", md: "lg" }}
      >
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
            >
              <Text textStyle="lg" fontWeight="medium">
                Product Categories
              </Text>
              <HStack>
                <Button onClick={createNewProductCategory.onOpen}>
                  Create New
                </Button>
              </HStack>
            </Stack>
            {(error || isLoading || !data) && <MMSpinner />}
          </Box>
          <Box overflowX="auto">
            <Table>
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Name</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.data.map((productCategory) => (
                  <Tr key={productCategory.id}>
                    <Td>
                      <Badge size="sm" colorScheme="blue">
                        {productCategory.id}
                      </Badge>
                    </Td>
                    <Td>
                      <Text>{productCategory.category}</Text>
                    </Td>
                    <Td>
                      <HStack spacing="1">
                        <IconButton
                          icon={<FiTrash2 />}
                          variant="tertiary"
                          aria-label="Delete"
                          onClick={() => {
                            setSelectedProductCategoryToDelete(productCategory);
                          }}
                        />
                        <IconButton
                          icon={<FiEdit2 />}
                          variant="tertiary"
                          aria-label="Edit"
                          onClick={() => {
                            setSelectedProductCategory(productCategory);
                            updateProductCategory.onOpen();
                          }}
                        />
                        <IconButton
                          icon={<FiPlayCircle />}
                          variant="tertiary"
                          aria-label="Update products"
                          onClick={() => {
                            setSelectedProductCategory(productCategory);
                            updateProductCategoryProducts.onOpen();
                          }}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box px={{ base: "4", md: "6" }} pb="5">
            <Pagination
              currentPage={currentPage}
              totalPages={data?.last_page ?? 1}
              handleNext={() => {
                handleNext(data?.last_page ?? 1);
              }}
              handlePrevious={handlePrev}
            />
          </Box>
        </Stack>
      </Box>
      <BaseDrawer
        isOpen={createNewProductCategory.isOpen}
        onClose={createNewProductCategory.onClose}
        size="md"
        header={
          <>
            <Box p={3} mt={3}>
              <PageHeader
                center
                title="Create a new product category"
                subtitle="Easy going!"
              />
            </Box>
          </>
        }
        placement="right"
        showCloseButton
      >
        <CreateNewProductCategoryDrawerContent
          onSuccess={onSuccessfullyCreated}
        />
      </BaseDrawer>
      {selectedProductCategory && (
        <BaseDrawer
          isOpen={updateProductCategoryProducts.isOpen}
          onClose={updateProductCategoryProducts.onClose}
          size="md"
          header={
            <>
              <Box p={3} mt={3}>
                <PageHeader
                  center
                  title="Update the products in the category"
                  subtitle="Easy going!"
                />
              </Box>
            </>
          }
          placement="right"
          showCloseButton
        >
          <UpdateProductsInCategoryDrawerContent
            productCategory={selectedProductCategory}
          />
        </BaseDrawer>
      )}
      {selectedProductCategory && (
        <BaseDrawer
          isOpen={updateProductCategory.isOpen}
          onClose={updateProductCategory.onClose}
          size="md"
          header={
            <>
              <Box p={3} mt={3}>
                <PageHeader
                  center
                  title="Update the product category"
                  subtitle="Easy going!"
                />
              </Box>
            </>
          }
          placement="right"
          showCloseButton
        >
          <UpdateProductCategoryDrawerContent
            productCategory={selectedProductCategory}
            onSuccess={() => {
              mutate();
            }}
          />
        </BaseDrawer>
      )}

      {selectedProductCategoryToDelete && (
        <DeleteConfirmationModal
          isOpen={selectedProductCategoryToDelete !== undefined}
          onClose={() => {
            setSelectedProductCategoryToDelete(undefined);
          }}
          onDelete={onDelete}
          description="The product category will be wiped from the system."
        />
      )}
    </>
  );
};
