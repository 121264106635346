import {
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiEdit2, FiPlayCircle, FiTrash2 } from "react-icons/fi";
import { Stack } from "@chakra-ui/react";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import { BaseDrawer } from "../../Components/Drawer/BaseDrawer";
import PageHeader from "../../Components/Typography/PageHeader";
import CreateNewMenuCycleDrawerContent from "./CreateNewMenuCycleDrawerContent";
import { useState } from "react";
import UpdateMenuCycleDrawerContent from "./UpdateMenuCycleDrawerContent";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";
import DeleteConfirmationModal from "../../Components/Modals/DeleteConfirmationModal";
import UpdateProductsInMenuCycleDrawerContent from "./UpdateProductsInMenuCycleDrawerContent";
import { MenuCycle } from "../../SWR/Types/MenuCycle";
import { useMenuCyclesIndex } from "../../SWR/EntityHooks/UseMenuCyclesIndex";

export const MenuCyclesTable = () => {
  const { data, error, isLoading, mutate } = useMenuCyclesIndex();

  const toast = useToast();

  const [selectedMenuCycle, setSelectedMenuCycle] = useState<
    MenuCycle | undefined
  >(undefined);

  const [selectedMenuCycleToDelete, setSelectedMenuCycleToDelete] = useState<
    MenuCycle | undefined
  >(undefined);

  const createNewMenuCycle = useDisclosure();
  const updateMenuCycle = useDisclosure();
  const updateMenuCycleProducts = useDisclosure();

  const onDelete = () => {
    apiClient
      .delete(ApiRoutes.MenuCycles.Delete(selectedMenuCycleToDelete?.id ?? 0))
      .then(() => {
        setSelectedMenuCycleToDelete(undefined);
        toast({
          title: "MenuCycle deleted!",
          status: "success",
          duration: 2000,
        });
        mutate();
      });
  };

  const onSuccessfullyCreated = () => {
    createNewMenuCycle.onClose();
    toast({
      title: "Created!",
      status: "success",
      duration: 2000,
    });
    mutate();
  };

  return (
    <>
      <Box
        bg="bg.surface"
        boxShadow={{ base: "none", md: "sm" }}
        borderRadius={{ base: "none", md: "lg" }}
      >
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
            >
              <Text textStyle="lg" fontWeight="medium">
                Menus/Cycles
              </Text>
              <HStack>
                <Button onClick={createNewMenuCycle.onOpen}>Create New</Button>
              </HStack>
            </Stack>
            {(error || isLoading || !data) && <MMSpinner />}
          </Box>
          <Box overflowX="auto">
            <Table>
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Name</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((menuCycle) => (
                  <Tr key={menuCycle.id}>
                    <Td>
                      <Badge size="sm" colorScheme="blue">
                        {menuCycle.id}
                      </Badge>
                    </Td>
                    <Td>
                      <Text>{menuCycle.name}</Text>
                    </Td>
                    <Td>
                      <HStack spacing="1">
                        <IconButton
                          icon={<FiTrash2 />}
                          variant="tertiary"
                          aria-label="Delete"
                          onClick={() => {
                            setSelectedMenuCycleToDelete(menuCycle);
                          }}
                        />
                        <IconButton
                          icon={<FiEdit2 />}
                          variant="tertiary"
                          aria-label="Edit"
                          onClick={() => {
                            setSelectedMenuCycle(menuCycle);
                            updateMenuCycle.onOpen();
                          }}
                        />
                        <IconButton
                          icon={<FiPlayCircle />}
                          variant="tertiary"
                          aria-label="Update products"
                          onClick={() => {
                            setSelectedMenuCycle(menuCycle);
                            updateMenuCycleProducts.onOpen();
                          }}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Stack>
      </Box>
      <BaseDrawer
        isOpen={createNewMenuCycle.isOpen}
        onClose={createNewMenuCycle.onClose}
        size="md"
        header={
          <>
            <Box p={3} mt={3}>
              <PageHeader
                center
                title="Create a new menu cycle"
                subtitle="Easy going!"
              />
            </Box>
          </>
        }
        placement="right"
        showCloseButton
      >
        <CreateNewMenuCycleDrawerContent onSuccess={onSuccessfullyCreated} />
      </BaseDrawer>
      {selectedMenuCycle && (
        <BaseDrawer
          isOpen={updateMenuCycleProducts.isOpen}
          onClose={updateMenuCycleProducts.onClose}
          size="md"
          header={
            <>
              <Box p={3} mt={3}>
                <PageHeader
                  center
                  title="Update the products in the Menu/Cycle"
                  subtitle="Easy going!"
                />
              </Box>
            </>
          }
          placement="right"
          showCloseButton
        >
          <UpdateProductsInMenuCycleDrawerContent
            menuCycle={selectedMenuCycle}
          />
        </BaseDrawer>
      )}
      {selectedMenuCycle && (
        <BaseDrawer
          isOpen={updateMenuCycle.isOpen}
          onClose={updateMenuCycle.onClose}
          size="md"
          header={
            <>
              <Box p={3} mt={3}>
                <PageHeader
                  center
                  title="Update the menu cycle"
                  subtitle="Easy going!"
                />
              </Box>
            </>
          }
          placement="right"
          showCloseButton
        >
          <UpdateMenuCycleDrawerContent
            menuCycle={selectedMenuCycle}
            onSuccess={() => {
              mutate();
            }}
          />
        </BaseDrawer>
      )}

      {selectedMenuCycleToDelete && (
        <DeleteConfirmationModal
          isOpen={selectedMenuCycleToDelete !== undefined}
          onClose={() => {
            setSelectedMenuCycleToDelete(undefined);
          }}
          onDelete={onDelete}
          description="The menu cycle will be wiped from the system."
        />
      )}
    </>
  );
};
