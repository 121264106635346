import {
  Badge,
  Box,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Pagination } from "../../Components/Pagination/pagination";
import { usePagination } from "../../Hooks/UsePagination";
import { Stack } from "@chakra-ui/react";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import { useOrdersIndex } from "../../SWR/EntityHooks/UseOrdersIndex";
import { FaEye } from "react-icons/fa";
import { useState } from "react";
import { Order } from "../../SWR/Types/Order";
import OrderDisplayModal from "../../Components/Orders/OrderDisplayModal";
import { useDetailedOrder } from "../../SWR/EntityHooks/UseDetailedOrder";

export const OrdersTable = () => {
  const { currentPage, handleNext, handlePrev } = usePagination();

  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>(
    undefined,
  );

  const { data: detailedOrder, isLoading: detailedOrderLoading } =
    useDetailedOrder({ orderId: selectedOrder?.id ?? 0 });

  const { data, error, isLoading } = useOrdersIndex({
    page: currentPage,
    perPage: 10,
  });

  return (
    <>
      <Box
        bg="bg.surface"
        boxShadow={{ base: "none", md: "sm" }}
        borderRadius={{ base: "none", md: "lg" }}
      >
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
            >
              <Text textStyle="lg" fontWeight="medium">
                Orders
              </Text>
            </Stack>
            {(error || isLoading || !data) && <MMSpinner />}
          </Box>
          <Box overflowX="auto">
            <Table>
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Date</Th>
                  <Th>Total</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.data.map((order) => (
                  <Tr key={order.id}>
                    <Td>
                      <Text>#{order.id}</Text>
                    </Td>
                    <Td>
                      <Badge size="sm" colorScheme="blue">
                        {new Date(order.created_at).toLocaleString()}
                      </Badge>
                    </Td>
                    <Td>
                      <Badge size="sm" colorScheme="green">
                        ${order.total_price_usd_cents / 100}
                      </Badge>
                    </Td>
                    <Td>
                      <HStack spacing="1">
                        <IconButton
                          icon={<FaEye />}
                          variant="tertiary"
                          aria-label="View"
                          onClick={() => {
                            setSelectedOrder(order);
                          }}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box px={{ base: "4", md: "6" }} pb="5">
            <Pagination
              currentPage={currentPage}
              totalPages={data?.last_page ?? 1}
              handleNext={() => {
                handleNext(data?.last_page ?? 1);
              }}
              handlePrevious={handlePrev}
            />
          </Box>
        </Stack>
      </Box>
      <OrderDisplayModal
        order={detailedOrder}
        isOpen={selectedOrder !== undefined && !detailedOrderLoading}
        onClose={() => setSelectedOrder(undefined)}
      />
    </>
  );
};
