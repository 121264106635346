import useSWR from "swr";
import { MenuCycleWithProducts } from "../Types/MenuCycle";

export type UseMenuCycleProps = {
  id: number;
};

export const useMenuCycle = (props: UseMenuCycleProps) => {
  const url = props.id === 0 ? null : `/menu-cycles/${props.id}`;
  const { data, error, mutate } = useSWR<MenuCycleWithProducts>(url, {
    revalidateOnFocus: false,
    refreshInterval: 0,
    revalidateOnMount: true,
  });

  const isLoading = !data && !error;

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};
