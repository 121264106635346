import PageHeader from "../../Components/Typography/PageHeader";
import { OrdersTable } from "./OrdersTable";

export const OrdersIndex = () => {
  return (
    <>
      <PageHeader
        title="Orders"
        subtitle="See all orders in an organized manner"
      />

      <OrdersTable />
    </>
  );
};
