import { Button, Stack, useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { RequestErrors } from "../../Components/ErrorHandling/RequestErrors";
import { HookForm } from "../../Components/Forms/HookForm/HookForm";
import { HookInput } from "../../Components/Forms/HookForm/HookInputField";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import { useSubmitData } from "../../Hooks/UseSubmitData";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";
import { useProductCategory } from "../../SWR/EntityHooks/UseProductCategory";
import { ProductCategory } from "../../SWR/Types/ProductCategory";

type UpdateProductCategoryDrawerContentProps = {
  productCategory?: ProductCategory;
  onSuccess: () => void;
};

const updateProductCategorySchema = z.object({
  category: z.string().min(3),
});

type UpdateProductCategoryFormValues = z.infer<
  typeof updateProductCategorySchema
>;

export default function UpdateProductCategoryDrawerContent({
  productCategory,
  onSuccess,
}: UpdateProductCategoryDrawerContentProps) {
  const toast = useToast();

  const {
    data: fetchedProductCategory,
    error: fetchProductCategoryError,
    isLoading: fetchProductCategoryLoading,
    mutate,
  } = useProductCategory({
    id: productCategory?.id ?? 0,
  });

  const {
    onSubmit,
    error: updateError,
    isLoading,
  } = useSubmitData<UpdateProductCategoryFormValues>({
    submitMethod: (data) =>
      apiClient.put(
        ApiRoutes.ProductCategories.Update(productCategory?.id ?? 0),
        data,
      ),
    runAfterSuccess: () => {
      toast({
        title: "Updated!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      mutate();
      onSuccess();
    },
    runAfterError: () => {},
  });

  if (
    !fetchedProductCategory ||
    fetchProductCategoryLoading ||
    fetchProductCategoryError
  ) {
    return <MMSpinner />;
  }

  return (
    <>
      <Stack>
        <HookForm<UpdateProductCategoryFormValues>
          onSubmit={onSubmit}
          form={{
            resolver: zodResolver(updateProductCategorySchema),
          }}
        >
          <Stack spacing="6">
            <HookInput
              name="category"
              label="Category"
              required
              defaultValue={fetchedProductCategory.category}
            />
            <Button type="submit">Confirm</Button>
            <RequestErrors error={updateError} />
          </Stack>
          {isLoading ? <MMSpinner /> : <></>}
        </HookForm>
      </Stack>
    </>
  );
}
