import { Button, Stack } from "@chakra-ui/react";
import { z } from "zod";
import { HookForm } from "../../Components/Forms/HookForm/HookForm";
import { useSubmitData } from "../../Hooks/UseSubmitData";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";
import { zodResolver } from "@hookform/resolvers/zod";
import { RequestErrors } from "../../Components/ErrorHandling/RequestErrors";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import { HookInput } from "../../Components/Forms/HookForm/HookInputField";
import { ProductConfiguration } from "../../SWR/Types/ProductConfiguration";

const updateProductConfigurationSchema = z.object({
  name: z.string().min(3),
});

type UpdateProductConfigurationFormValues = z.infer<
  typeof updateProductConfigurationSchema
>;

export type UpdateProductConfigurationDrawerContentProps = {
  productConfiguration?: ProductConfiguration;
  onSuccess: () => void;
};

export default function UpdateProductConfigurationDrawerContent({
  productConfiguration,
  onSuccess,
}: UpdateProductConfigurationDrawerContentProps) {
  const {
    onSubmit,
    error: updateError,
    isLoading,
  } = useSubmitData<UpdateProductConfigurationFormValues>({
    submitMethod: (data) =>
      apiClient.put(
        ApiRoutes.ProductConfigurations.Update(productConfiguration?.id ?? 0),
        data,
      ),
    runAfterSuccess: () => {
      onSuccess();
    },
    runAfterError: () => {},
  });

  if (!productConfiguration) {
    return <MMSpinner />;
  }

  return (
    <>
      <Stack>
        <HookForm<UpdateProductConfigurationFormValues>
          onSubmit={onSubmit}
          form={{
            resolver: zodResolver(updateProductConfigurationSchema),
          }}
        >
          <Stack spacing="6">
            <HookInput
              name="name"
              label="Name"
              required
              defaultValue={productConfiguration.name}
            />
            <Button type="submit">Confirm</Button>
            <RequestErrors error={updateError} />
          </Stack>
          {isLoading ? <MMSpinner /> : <></>}
        </HookForm>
      </Stack>
    </>
  );
}
