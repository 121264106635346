import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import { CommonHookFormFieldProps } from "./HookForm/Types";

export const FilterInput = ({
  name,
  type,
  defaultValue,
  placeholder,
  onChange,
  label = "",
  showLabel = true,
  readonly = false,
}: CommonHookFormFieldProps & {
  placeholder: string;
  onChange: (arg0: string) => void;
}) => {
  return (
    <FormControl width={{ base: "full", md: "unset" }}>
      {showLabel && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Input
        id={name}
        placeholder={placeholder}
        readOnly={readonly}
        onChange={(e) => onChange(e.target.value)}
        borderColor="gray"
        type={type}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};
