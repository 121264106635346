import useSWR from "swr";
import { ProductConfiguration } from "../Types/ProductConfiguration";

export const useProductConfigurationsIndex = () => {
  const { data, error, mutate } = useSWR<ProductConfiguration[]>(
    `/product-configurations`,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  );

  const isLoading = !data && !error;

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};
