import useSWR from "swr";
import { CustomerWithOrders } from "../Types/Customer";

export type UseCustomerProps = {
  id: number;
};

export const useCustomer = (props: UseCustomerProps) => {
  const { data, error, mutate } = useSWR<CustomerWithOrders>(
    `/customers/${props.id}`,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  );

  const isLoading = !data && !error;

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};
