import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useDashboardStats } from "../../SWR/EntityHooks/UseDashboardStats";

export default function DashboardStatsIndex() {
  const InfoBox = ({ title, value }: { title: string; value: string }) => {
    return (
      <Box
        mb={5}
        maxWidth="300px"
        minHeight={{ base: "80px", md: "150px" }}
        backgroundColor={"white"}
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        borderRadius="5px"
        boxShadow="4px 4px 4px 4px"
        textAlign="center"
      >
        <Text fontSize="xl" fontWeight="bold" color="black">
          {title}
        </Text>
        <Text fontSize="2xl" fontWeight="bold" color="black">
          {value}
        </Text>
      </Box>
    );
  };

  const dashboardStats = useDashboardStats();

  const BestProduct = () => {
    if (
      !dashboardStats?.data ||
      dashboardStats.data.orderedProductsAnalysis.length <= 0
    ) {
      return <></>;
    }

    const product = dashboardStats.data.orderedProductsAnalysis[0];
    return <InfoBox title={product.name} value={product.count.toString()} />;
  };

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 5 }}>
        <InfoBox
          title="Total Orders"
          value={dashboardStats.data?.totalOrders.toString() ?? ""}
        />
        <InfoBox
          title="Unpaid Orders"
          value={dashboardStats.data?.unpaidOrders.toString() ?? ""}
        />
        <InfoBox
          title="Total Revenue"
          value={`${
            (
              (dashboardStats.data?.totalPaidOrdersMoneyAmount ?? 0) / 100
            ).toString() ?? ""
          } AUD`}
        />
        <InfoBox
          title="Total Lost Revenue (Unpaid Orders)"
          value={`${
            (
              (dashboardStats.data?.totalUnpaidOrdersMoneyAmount ?? 0) / 100
            ).toString() ?? ""
          } AUD`}
        />
        <BestProduct />
      </SimpleGrid>
    </>
  );
}
