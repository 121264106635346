import useSWR from "swr";
import { PaginatedResponse } from "../Types/PaginatedResponse";
import { Product } from "../Types/Product";

export type UseProductsIndexProps = {
  page: number;
  perPage: number;
  search: string;
};

export const useProductsIndex = (
  props: UseProductsIndexProps = { page: 1, perPage: 10, search: "" },
) => {
  const { data, error, mutate } = useSWR<PaginatedResponse<Product>>(
    `/products?page=${props.page}&per_page=${props.perPage}&search=${props.search}`,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  );

  // Cache Call
  useSWR<PaginatedResponse<Product>>(
    `/products?page=${props.page + 1}&per_page=${props.perPage}`,
  );

  const isLoading = !data && !error;

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};
