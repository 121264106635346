import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { CommonHookFormFieldProps } from "./Types";

export const HookSelect = ({
  name,
  label,
  options,
  required,
  defaultValue,
}: CommonHookFormFieldProps & {
  options: { label: string; value: string | number }[];
}) => {
  const methods = useFormContext();
  const error = methods.formState.errors[name];

  return (
    <FormControl isRequired={required} isInvalid={!!error}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        borderColor="gray"
        id={name}
        placeholder={label}
        defaultValue={defaultValue}
        {...methods.register(name)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>
        <Text>{error && error.message?.toString()}</Text>
      </FormErrorMessage>
    </FormControl>
  );
};
