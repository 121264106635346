import { Box, Button, HStack, useToast, VStack } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { z } from "zod";
import { RequestErrors } from "../../Components/ErrorHandling/RequestErrors";
import { HookForm } from "../../Components/Forms/HookForm/HookForm";
import { HookInput } from "../../Components/Forms/HookForm/HookInputField";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import DeleteConfirmationModal from "../../Components/Modals/DeleteConfirmationModal";
import { useSubmitData } from "../../Hooks/UseSubmitData";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";
import { useProductConfigurationOptions } from "../../SWR/EntityHooks/UseProductConfigurationOptions";
import { ProductConfiguration } from "../../SWR/Types/ProductConfiguration";
import { ProductConfigurationOption } from "../../SWR/Types/ProductConfigurationOption";

const updateOrCreateProductConfigurationOptionSchema = z.object({
  name: z.string().min(3),
});

type UpdateOrCreateProductConfigurationOptionSchema = z.infer<
  typeof updateOrCreateProductConfigurationOptionSchema
>;

export type UpdateProductConfigurationDrawerContentProps = {
  productConfiguration?: ProductConfiguration;
  onSuccess: () => void;
};

export type ProductConfigurationOptionsDrawerContentProps = {
  productConfiguration?: ProductConfiguration;
};

export default function ProductConfigurationOptionsDrawerContent({
  productConfiguration,
}: ProductConfigurationOptionsDrawerContentProps) {
  const toast = useToast();

  const [
    selectedProductConfigurationOptionId,
    setSelectedProductConfigurationOptionId,
  ] = useState<number | undefined>(undefined);

  const [
    selectedProductConfigurationOptionIdToDelete,
    setSelectedProductConfigurationOptionIdToDelete,
  ] = useState<number | undefined>(undefined);

  const {
    data: productConfigurationOptions,
    isLoading: optionsLoading,
    error: optionsLoadingError,
    mutate,
  } = useProductConfigurationOptions({
    productConfigurationId: productConfiguration?.id ?? 0,
  });

  const deleteOption = () => {
    apiClient
      .delete(
        ApiRoutes.ProductConfigurationOptions.Delete(
          productConfiguration?.id ?? 0,
          selectedProductConfigurationOptionIdToDelete ?? 0,
        ),
      )
      .then(() => {
        toast({
          title: "Deleted!",
          status: "success",
          duration: 2000,
        });
        mutate();
        setSelectedProductConfigurationOptionIdToDelete(undefined);
      });
  };

  const {
    onSubmit: onSubmitUpdate,
    error: updateError,
    isLoading: isUpdateSubmitLoading,
  } = useSubmitData<UpdateOrCreateProductConfigurationOptionSchema>({
    submitMethod: (data) =>
      apiClient.put(
        ApiRoutes.ProductConfigurationOptions.Update(
          productConfiguration?.id ?? 0,
          selectedProductConfigurationOptionId ?? 0,
        ),
        data,
      ),
    runAfterSuccess: () => {
      mutate();
      toast({
        title: "Updated!",
        status: "success",
        duration: 2000,
      });
    },
  });

  const {
    onSubmit: onSubmitCreate,
    error: createError,
    isLoading: isCreateSubmitLoading,
  } = useSubmitData<UpdateOrCreateProductConfigurationOptionSchema>({
    submitMethod: (data) =>
      apiClient.post(
        ApiRoutes.ProductConfigurationOptions.Create(
          productConfiguration?.id ?? 0,
        ),
        data,
      ),
    runAfterSuccess: () => {
      mutate();
      toast({
        title: "Created!",
        status: "success",
        duration: 2000,
      });
    },
    runAfterError: () => {},
  });

  if (!productConfiguration) {
    return <MMSpinner />;
  }

  const renderExistingOption = (option: ProductConfigurationOption) => {
    return (
      <HookForm<UpdateOrCreateProductConfigurationOptionSchema>
        onSubmit={onSubmitUpdate}
        form={{
          resolver: zodResolver(updateOrCreateProductConfigurationOptionSchema),
        }}
      >
        <HStack justifyContent="start" alignItems="end" spacing={5}>
          <HookInput
            name="name"
            label="Name"
            defaultValue={option.name}
            required
          />
          <HStack>
            <Button
              onClick={() => {
                setSelectedProductConfigurationOptionId(option.id);
              }}
              type="submit"
            >
              Update
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                setSelectedProductConfigurationOptionIdToDelete(option.id);
              }}
            >
              Delete
            </Button>
          </HStack>
        </HStack>
      </HookForm>
    );
  };

  const renderCreateNewOption = () => {
    return (
      <HookForm<UpdateOrCreateProductConfigurationOptionSchema>
        onSubmit={onSubmitCreate}
        form={{
          resolver: zodResolver(updateOrCreateProductConfigurationOptionSchema),
        }}
      >
        <HStack justifyContent="start" alignItems="end" spacing={5}>
          <HookInput name="name" label="Name" required />
          <HStack>
            <Button type="submit">Create</Button>
          </HStack>
        </HStack>
      </HookForm>
    );
  };

  return (
    <>
      <VStack spacing={5} alignItems="start">
        {(optionsLoading || optionsLoadingError) && <MMSpinner />}
        {productConfigurationOptions?.map((option, i) => {
          return <Box key={i}>{renderExistingOption(option)}</Box>;
        })}
        {renderCreateNewOption()}
        <Box mt={2}>
          <RequestErrors error={updateError} />
          <RequestErrors error={createError} />
          {(isUpdateSubmitLoading || isCreateSubmitLoading) && <MMSpinner />}
        </Box>
      </VStack>
      <DeleteConfirmationModal
        isOpen={!!selectedProductConfigurationOptionIdToDelete}
        onClose={() => {
          setSelectedProductConfigurationOptionIdToDelete(undefined);
        }}
        onDelete={deleteOption}
        description="This will remove the option from the configuration. All products that use this option will be affected."
      />
    </>
  );
}
