import {
  Center,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Square,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { FiUploadCloud } from "react-icons/fi";
import MMSpinner from "../Indicators/MMSpinner";

type DropzoneProps = {
  title: string;
  description: string;
  fileSelectedCallback: (arg0: File) => void;
  loading: boolean;
  inputName: string;
};

export default function Dropzone({
  description,
  fileSelectedCallback,
  loading,
  inputName,
}: DropzoneProps) {
  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      fileSelectedCallback(file);
    }
  };

  return (
    <Center borderWidth="1px" borderRadius="lg" px="6" py="4" width="full">
      <VStack spacing="3">
        <FormControl>
          <Input
            type="file"
            id={inputName}
            display="none"
            onChange={handleFileSelect}
          />
          <FormLabel htmlFor={inputName}>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Square size="10" borderRadius="lg">
                <Icon as={FiUploadCloud} boxSize="5" />
              </Square>
              <Text textStyle="md" fontWeight="bold">
                {description}
              </Text>
              {loading && <MMSpinner />}
            </Flex>
          </FormLabel>
        </FormControl>
      </VStack>
    </Center>
  );
}
