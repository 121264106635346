import { useState } from "react";

export const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleNext = (totalPages: number) => {
    setCurrentPage((prev) => (currentPage < totalPages ? prev + 1 : prev));
  };

  const handlePrev = () => {
    setCurrentPage((prev) => (currentPage > 1 ? prev - 1 : prev));
  };

  return {
    currentPage,
    setCurrentPage,
    handleNext,
    handlePrev,
  };
};
