import {
  Badge,
  Button,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { z } from "zod";
import { RequestErrors } from "../../Components/ErrorHandling/RequestErrors";
import { FilterSelect } from "../../Components/Forms/FilterSelect";
import { HookForm } from "../../Components/Forms/HookForm/HookForm";
import { HookInput } from "../../Components/Forms/HookForm/HookInputField";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import { useSubmitData } from "../../Hooks/UseSubmitData";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";
import { useProductConfigurationOptions } from "../../SWR/EntityHooks/UseProductConfigurationOptions";
import { useProductConfigurationsIndex } from "../../SWR/EntityHooks/UseProductConfigurationsIndex";
import { Product } from "../../SWR/Types/Product";
import { ProductConfigurationOption } from "../../SWR/Types/ProductConfigurationOption";

const createProductProductConfigurationOptionsSchema = z.object({
  price_usd_cents: z.string(),
});

type CreateProductProductConfigurationOptionsFormValues = z.infer<
  typeof createProductProductConfigurationOptionsSchema
>;

type AttachProductProductConfigurationOptionModalContentProps = {
  product: Product;
  onSuccessCreate: () => void;
};

export default function AttachProductProductConfigurationOptionModalContent({
  product,
  onSuccessCreate,
}: AttachProductProductConfigurationOptionModalContentProps) {
  const toast = useToast();

  const {
    data: productConfigurations,
    isLoading: isProductConfigurationsLoading,
    error: productConfigurationsIndexError,
  } = useProductConfigurationsIndex();

  const [selectedProductConfigurationId, setSelectedProductConfigurationId] =
    useState<number | undefined>(undefined);

  const [
    selectedProductConfigurationOption,
    setSelectedProductConfigurationOption,
  ] = useState<ProductConfigurationOption | undefined>(undefined);

  const {
    data: productConfigurationOptions,
    isLoading: isProductConfigurationOptionsLoading,
  } = useProductConfigurationOptions({
    productConfigurationId: selectedProductConfigurationId ?? 0,
  });

  const {
    onSubmit: onSubmitCreate,
    error: createError,
    isLoading: isCreateSubmitLoading,
  } = useSubmitData<CreateProductProductConfigurationOptionsFormValues>({
    submitMethod: (data) =>
      apiClient.post(
        ApiRoutes.Products.ProductConfigurationOptions.Create(
          product?.id ?? 0,
          selectedProductConfigurationOption?.id ?? 0,
        ),
        data,
      ),
    runAfterSuccess: () => {
      toast({
        title: "Created!",
        status: "success",
        duration: 2000,
      });
      onSuccessCreate();
    },
    runAfterError: () => {
      toast({
        title: "Failed!",
        description: "The option probably already exists for this product",
        status: "error",
        duration: 3000,
      });
    },
  });

  if (
    !product ||
    !productConfigurations ||
    isProductConfigurationsLoading ||
    productConfigurationsIndexError
  ) {
    return <MMSpinner />;
  }

  const productConfigurationSelect = () => {
    return productConfigurations.map((config) => ({
      label: config.name,
      value: config.id,
    }));
  };

  const productConfigurationOptionsSelect = () => {
    if (!productConfigurationOptions) {
      return [];
    }

    return productConfigurationOptions?.map((option) => ({
      label: option.name,
      value: option.id,
    }));
  };

  return (
    <>
      <VStack justifyContent="start" alignItems="start" pb={5}>
        <Text fontSize="md" fontWeight="bold">
          Choose a configuration
        </Text>
        <FilterSelect
          name="product_configuration_id"
          label="Product Configuration"
          onChange={(e) => {
            setSelectedProductConfigurationId(Number(e.target.value));
          }}
          options={productConfigurationSelect()}
        />
        {(isProductConfigurationOptionsLoading || isCreateSubmitLoading) && (
          <MMSpinner />
        )}
        {selectedProductConfigurationId &&
          selectedProductConfigurationId !== 0 && (
            <>
              <Text fontSize="md" fontWeight="bold" mt={3}>
                Choose an option
              </Text>
              <FilterSelect
                name="product_configuration_option_id"
                label="Configuration Option"
                options={productConfigurationOptionsSelect()}
                onChange={(e) => {
                  setSelectedProductConfigurationOption(
                    productConfigurationOptions?.find(
                      (o) => o.id === Number(e.target.value),
                    ),
                  );
                }}
              />
            </>
          )}
        {selectedProductConfigurationOption && (
          <HookForm<CreateProductProductConfigurationOptionsFormValues>
            onSubmit={onSubmitCreate}
            form={{
              resolver: zodResolver(
                createProductProductConfigurationOptionsSchema,
              ),
            }}
          >
            <HStack justifyContent="space-between" alignItems="end" mb={3}>
              <Badge
                key={selectedProductConfigurationOption.id}
                p={2}
                pl={16}
                pr={16}
                colorScheme="green"
              >
                {selectedProductConfigurationOption.name}
              </Badge>
              <HookInput
                type="number"
                name="price_usd_cents"
                label="Price (USD Cents)"
              />
            </HStack>
            <HStack>
              <Button flexGrow={1} type="submit">
                Create
              </Button>
            </HStack>
          </HookForm>
        )}
        <RequestErrors error={createError} />
      </VStack>
    </>
  );
}
