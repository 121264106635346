import PageHeader from "../../../Components/Typography/PageHeader";
import { PicturesTable } from "./PicturesTable";

export default function PicturesIndex() {
  return (
    <>
      <PageHeader
        title="SEO - Pictures"
        subtitle="Set alt texts of your pictures for SEO"
      />
      <PicturesTable />
    </>
  );
}
