import useSWR from "swr";
import { Product } from "../Types/Product";
import { ProductCategory } from "../Types/ProductCategory";

export type UseProductCategory = {
  id: number;
};

export type ProductCategoryWithProducts = ProductCategory & {
  products: Product[];
};

export const useProductCategory = (props: UseProductCategory) => {
  const url = props.id === 0 ? null : `/product-categories/${props.id}`;
  const { data, error, mutate } = useSWR<ProductCategoryWithProducts>(url, {
    revalidateOnFocus: false,
    refreshInterval: 30000,
  });

  const isLoading = !data && !error;

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};
