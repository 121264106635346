import useSWR from "swr";
import { MenuCycleWithProductCount } from "../Types/MenuCycle";

export const useMenuCyclesIndex = () => {
  const { data, error, mutate } = useSWR<MenuCycleWithProductCount[]>(
    `/menu-cycles`,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  );

  const isLoading = !data && !error;

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};
