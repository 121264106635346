import {
  Avatar,
  Badge,
  Box,
  HStack,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { OrderWithOrderedProductsAndOptions } from "../../SWR/Types/Order";
import { FilterInput } from "../Forms/FilterInputField";
import { BaseModal } from "../Modals/BaseModal";
import PageHeader from "../Typography/PageHeader";

type OrderDisplayModalProps = {
  order?: OrderWithOrderedProductsAndOptions;
  isOpen: boolean;
  onClose: () => void;
};

export default function OrderDisplayModal({
  order,
  isOpen,
  onClose,
}: OrderDisplayModalProps) {
  return (
    <BaseModal
      header={
        <>
          <PageHeader
            center
            title={`Order Details (#${order?.id}), $${
              (order?.total_price_usd_cents ?? 0) / 100
            }`}
            subtitle="Prices based on purchase date"
            subSubTitle={`${new Date(
              order?.created_at ?? "",
            ).toLocaleString()}`}
          />
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={true}
    >
      <Box bg="bg.surface" py="4" pl={1} pr={1}>
        <Stack divider={<StackDivider />} spacing="4">
          <Stack width="full">
            <FilterInput
              name="email"
              label="Shipment Information"
              type="string"
              readonly
              defaultValue={order?.email}
              onChange={() => {}}
              placeholder="E-Mail"
            />
            <FilterInput
              name="full_name"
              label="Full Name"
              type="string"
              readonly
              showLabel={false}
              defaultValue={order?.full_name}
              onChange={() => {}}
              placeholder="Full Name"
            />
            <FilterInput
              name="street_and_number"
              label="Street And Number"
              type="string"
              readonly
              showLabel={false}
              defaultValue={order?.street_and_number}
              onChange={() => {}}
              placeholder="Street And Number"
            />
            <FilterInput
              name="zip_code"
              label="ZIP Coded"
              type="string"
              readonly
              showLabel={false}
              defaultValue={order?.zip_code}
              onChange={() => {}}
              placeholder="ZIP Code"
            />
            <FilterInput
              name="company_name"
              label="Company Name"
              type="string"
              readonly
              showLabel={false}
              defaultValue={order?.company_name}
              onChange={() => {}}
              placeholder="Company Name"
            />
          </Stack>
          {order?.ordered_products.map((orderedProduct) => (
            <Stack key={orderedProduct.id} fontSize="sm" px="4" spacing="4">
              <Stack direction="row" justify="space-between" spacing="4">
                <HStack spacing="3">
                  <Avatar
                    src={orderedProduct.product.pictures[0]?.full_picture_url}
                    boxSize="10"
                  />
                  <Box>
                    <Text fontWeight="medium" color="fg.emphasized">
                      {orderedProduct.product.name}
                    </Text>
                    <Text color="fg.muted">{orderedProduct.product.sku}</Text>
                  </Box>
                </HStack>
                <VStack alignItems="end">
                  <Text color="fg.muted">
                    Total: $
                    {(orderedProduct.calculated_single_price_usd_cents *
                      orderedProduct.quantity) /
                      100}
                  </Text>

                  <Text color="fg.muted">
                    Quantity: {orderedProduct.quantity}
                  </Text>
                  <Text color="fg.muted">
                    Price: $
                    {orderedProduct.calculated_single_price_usd_cents / 100}
                  </Text>
                </VStack>
              </Stack>
              <VStack alignItems="start">
                <Text>Configurations</Text>
                <HStack>
                  {orderedProduct.product_configuration_options.map((e) => {
                    return (
                      <Badge key={e.id}>
                        {e.name} ($
                        {e.pivot.option_price_at_purchase_usd_cents / 100})
                      </Badge>
                    );
                  })}
                </HStack>
              </VStack>
            </Stack>
          ))}
          <VStack alignItems="end">
            <HStack>
              <Text>Order Status:</Text>
              <Badge colorScheme={order?.paid ? "green" : "red"}>
                {order?.paid ? "Paid" : "Not Paid"}
              </Badge>
            </HStack>
            <Text>Customer ID: {order?.customer_id}</Text>
          </VStack>
        </Stack>
      </Box>
    </BaseModal>
  );
}
