import {
  Badge,
  Box,
  Button,
  HStack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { z } from "zod";
import { RequestErrors } from "../../Components/ErrorHandling/RequestErrors";
import { HookForm } from "../../Components/Forms/HookForm/HookForm";
import { HookInput } from "../../Components/Forms/HookForm/HookInputField";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import { BaseModal } from "../../Components/Modals/BaseModal";
import DeleteConfirmationModal from "../../Components/Modals/DeleteConfirmationModal";
import { useSubmitData } from "../../Hooks/UseSubmitData";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";
import {
  TransformedProductProductConfigurationOption,
  useProductProductConfigurationOptionsIndex,
} from "../../SWR/EntityHooks/UseProductProductConfigurationOptionsIndex";
import { Product } from "../../SWR/Types/Product";
import AttachProductProductConfigurationOptionModalContent from "./AttachProductProductConfigurationOptionModalContent";

const updateProductProductConfigurationOptionsSchema = z.object({
  price_usd_cents: z.string(),
});

type UpdateProductProductConfigurationOptionsFormValues = z.infer<
  typeof updateProductProductConfigurationOptionsSchema
>;

type UpdateProductProductConfigurationOptionsDrawerContentProps = {
  product?: Product;
};

export default function UpdateProductProductConfigurationOptionsDrawerContent({
  product,
}: UpdateProductProductConfigurationOptionsDrawerContentProps) {
  const {
    data,
    transformed: configurationsAndOptions,
    error,
    mutate,
    isLoading,
  } = useProductProductConfigurationOptionsIndex({
    productId: product?.id ?? 0,
  });

  const toast = useToast();
  const attachProductProductConfigurationOption = useDisclosure();

  const [
    selectedProductConfigurationOptionId,
    setSelectedProductConfigurationOptionId,
  ] = useState<number | undefined>(undefined);

  const [
    selectedProductConfigurationOptionIdToDelete,
    setSelectedProductConfigurationOptionIdToDelete,
  ] = useState<number | undefined>(undefined);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const {
    onSubmit: onSubmitUpdate,
    error: updateError,
    isLoading: isUpdateSubmitLoading,
  } = useSubmitData<UpdateProductProductConfigurationOptionsFormValues>({
    submitMethod: (data) =>
      apiClient.put(
        ApiRoutes.Products.ProductConfigurationOptions.Update(
          product?.id ?? 0,
          selectedProductConfigurationOptionId ?? 0,
        ),
        data,
      ),
    runAfterSuccess: () => {
      mutate();
      toast({
        title: "Updated!",
        status: "success",
        duration: 2000,
      });
    },
  });

  const onDeleteSelected = () => {
    if (selectedProductConfigurationOptionIdToDelete === undefined) {
      return;
    }

    setIsDeleteLoading(true);
    apiClient
      .delete(
        ApiRoutes.Products.ProductConfigurationOptions.Delete(
          product?.id ?? 0,
          selectedProductConfigurationOptionIdToDelete,
        ),
      )
      .then(() => {
        setIsDeleteLoading(false);
        setSelectedProductConfigurationOptionIdToDelete(undefined);
        mutate();
        toast({
          title: "Deleted!",
          status: "success",
          duration: 2000,
        });
      });
  };

  if (!product || !data || error || isLoading) {
    return <MMSpinner />;
  }

  const renderOneConfigurationAndOptions = (
    entry: TransformedProductProductConfigurationOption,
  ) => {
    return (
      <Box
        key={entry.configuration.id}
        borderWidth="4px"
        borderStyle="dotted"
        justifyContent="start"
        width="full"
        rounded="md"
        p={3}
      >
        <Text fontSize="lg" fontWeight="bold" mb={3}>
          {entry.configuration.name}
        </Text>
        <VStack spacing={16} width="full">
          {entry.options.map((option, i) => (
            <Box width="full" justifyContent="start" key={i}>
              <HookForm<UpdateProductProductConfigurationOptionsFormValues>
                onSubmit={onSubmitUpdate}
                form={{
                  resolver: zodResolver(
                    updateProductProductConfigurationOptionsSchema,
                  ),
                }}
              >
                <HStack justifyContent="space-between" alignItems="end" mb={3}>
                  <Badge
                    key={option.id}
                    p={2}
                    pl={16}
                    pr={16}
                    colorScheme="green"
                  >
                    {option.name}
                  </Badge>
                  <HookInput
                    defaultValue={option.pivot.price_usd_cents}
                    type="number"
                    name="price_usd_cents"
                    label="Price (USD Cents)"
                  />
                </HStack>
                <HStack>
                  <Button
                    flexGrow={1}
                    type="submit"
                    onClick={() => {
                      setSelectedProductConfigurationOptionId(option.id);
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    colorScheme="red"
                    flexGrow={1}
                    onClick={() => {
                      setSelectedProductConfigurationOptionIdToDelete(
                        option.id,
                      );
                    }}
                  >
                    Delete
                  </Button>
                </HStack>
              </HookForm>
            </Box>
          ))}
        </VStack>
      </Box>
    );
  };

  return (
    <>
      <VStack alignItems="center" justifyContent="center" spacing={20}>
        <Button
          width="full"
          onClick={attachProductProductConfigurationOption.onToggle}
        >
          Attach new option
        </Button>
        <RequestErrors error={updateError} />
        {(isLoading || isUpdateSubmitLoading || isDeleteLoading) && (
          <MMSpinner />
        )}
        {configurationsAndOptions.map(renderOneConfigurationAndOptions)}
      </VStack>
      <DeleteConfirmationModal
        isOpen={selectedProductConfigurationOptionIdToDelete !== undefined}
        onClose={() =>
          setSelectedProductConfigurationOptionIdToDelete(undefined)
        }
        description="The configuration option will be removed from the product."
        onDelete={onDeleteSelected}
      />

      <BaseModal
        onClose={attachProductProductConfigurationOption.onClose}
        isOpen={attachProductProductConfigurationOption.isOpen}
        header={
          <>
            <Text textAlign="center">
              Attach a product configuration option
            </Text>
          </>
        }
        showCloseButton
      >
        <AttachProductProductConfigurationOptionModalContent
          onSuccessCreate={() => {
            mutate();
            attachProductProductConfigurationOption.onClose();
          }}
          product={product}
        />
      </BaseModal>
    </>
  );
}
