import PageHeader from "../../Components/Typography/PageHeader";
import { ProductCategoriesTable } from "./ProductCategoriesTable";

export const ProductCategoriesIndex = () => {
  return (
    <>
      <PageHeader
        title="Product Categories"
        subtitle="Your Product Categories"
      />
      <ProductCategoriesTable />
    </>
  );
};
