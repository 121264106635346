import { Box, Button, Stack, useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { z } from "zod";
import { RequestErrors } from "../../Components/ErrorHandling/RequestErrors";
import Dropzone from "../../Components/Forms/Dropzone";
import { HookForm } from "../../Components/Forms/HookForm/HookForm";
import { HookInput } from "../../Components/Forms/HookForm/HookInputField";
import { HookSelect } from "../../Components/Forms/HookForm/HookSelect";
import { HookSwitch } from "../../Components/Forms/HookForm/HookSwitch";
import { HookTextarea } from "../../Components/Forms/HookForm/HookTextarea";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import DeleteConfirmationModal from "../../Components/Modals/DeleteConfirmationModal";
import { useSubmitData } from "../../Hooks/UseSubmitData";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";
import { useProduct } from "../../SWR/EntityHooks/UseProduct";
import { Picture } from "../../SWR/Types/Picture";
import { Product } from "../../SWR/Types/Product";
import { ProductTypeEnum } from "../../SWR/Types/ProductType";
import Gallery from "./Gallery";

type UpdateProductDrawerContentProps = {
  product?: Product;
  onSuccess: () => void;
  onPictureDeleted: () => void;
  onPictureUploaded: () => void;
};

const updateProductSchema = z.object({
  name: z.string().min(3),
  sku: z.string().min(3),
  display_price_usd_cents: z.string(),
  description: z.string(),
  product_type: z.string(),
  cycled: z.boolean(),
});

type UpdateProductFormValues = z.infer<typeof updateProductSchema>;

export default function UpdateProductDrawerContent({
  product,
  onSuccess,
  onPictureDeleted,
  onPictureUploaded,
}: UpdateProductDrawerContentProps) {
  const toast = useToast();

  const [selectedPictureToDelete, setSelectedPictureToDelete] = useState<
    Picture | undefined
  >(undefined);

  const [renderGallery, setRenderGallery] = useState(true);

  const {
    data: fetchedProduct,
    error: fetchProductError,
    isLoading: fetchProductLoading,
    mutate,
  } = useProduct({
    id: product?.id ?? 0,
  });

  const onDeletePicture = () => {
    if (selectedPictureToDelete) {
      apiClient
        .delete(ApiRoutes.Pictures.Delete(selectedPictureToDelete.id))
        .then(() => {
          setSelectedPictureToDelete(undefined);
          toast({
            title: "Deleted!",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          mutate();
          onPictureDeleted();
        });
    }
  };

  const onUploadNewPicture = (file: File) => {
    setRenderGallery(false);

    const formData = new FormData();
    formData.append("image", file);

    apiClient
      .post(
        ApiRoutes.Products.AddProductPicture(fetchedProduct?.id ?? 0),
        formData,
      )
      .then(() => {
        toast({
          title: "Uploaded!",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        onPictureUploaded();
        mutate();

        setTimeout(() => {
          setRenderGallery(true);
        }, 100);
      });
  };

  const productTypeOptions = () => {
    return [
      { label: "Simple", value: ProductTypeEnum.Simple },
      { label: "Configurable", value: ProductTypeEnum.Configurable },
    ];
  };

  const {
    onSubmit,
    error: updateError,
    isLoading,
  } = useSubmitData<UpdateProductFormValues>({
    submitMethod: (data) =>
      apiClient.put(ApiRoutes.Products.Update(product?.id ?? 0), data),
    runAfterSuccess: () => {
      toast({
        title: "Updated!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      onSuccess();
      mutate();
    },
    runAfterError: () => {},
  });

  if (!fetchedProduct || fetchProductLoading || fetchProductError) {
    return <MMSpinner />;
  }

  return (
    <>
      <Stack>
        <HookForm<UpdateProductFormValues>
          onSubmit={onSubmit}
          form={{
            resolver: zodResolver(updateProductSchema),
          }}
        >
          <Stack spacing="6">
            <HookInput
              name="name"
              label="Name"
              required
              defaultValue={fetchedProduct.name}
            />
            <HookInput
              name="sku"
              label="SKU"
              required
              defaultValue={fetchedProduct.sku}
            />
            <HookInput
              name="display_price_usd_cents"
              label="Display Price (USD Cents)"
              type="number"
              required
              defaultValue={fetchedProduct.display_price_usd_cents}
            />
            <HookTextarea
              name="description"
              label="Description"
              defaultValue={fetchedProduct.description}
            />
            <HookSelect
              name="product_type"
              label="Product Type"
              defaultValue={fetchedProduct.product_type}
              options={productTypeOptions()}
              required
            />
            <HookSwitch
              defaultChecked={fetchedProduct.cycled}
              name="cycled"
              label="Menu Cycle Only"
            />
            <Button type="submit">Confirm</Button>
            <RequestErrors error={updateError} />
          </Stack>
          {isLoading ? <MMSpinner /> : <></>}
        </HookForm>
        {!renderGallery && <MMSpinner />}
        {renderGallery && (
          <Box mt={10}>
            <Gallery
              images={fetchedProduct.pictures}
              onDeletePicture={setSelectedPictureToDelete}
            />
          </Box>
        )}
        <Box mt={3}>
          <Dropzone
            title="Upload a new product image"
            description="Click to select and upload a new image"
            fileSelectedCallback={onUploadNewPicture}
            loading={false}
            inputName="file"
          />
        </Box>
      </Stack>
      <DeleteConfirmationModal
        isOpen={!!selectedPictureToDelete}
        onClose={() => {
          setSelectedPictureToDelete(undefined);
        }}
        onDelete={onDeletePicture}
        description="The image will be removed from the product and therefore also from the shop site. It will not be recoverable."
      />
    </>
  );
}
