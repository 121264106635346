import axios, { AxiosInstance } from "axios";

function setupApiClient(): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_API_URL}`,
    withCredentials: true,
    withXSRFToken: true,
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("key");
      if (token) {
        config.headers["X-Master-Password"] = `${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    async (response) => response,
    async (error) => {
      const { status } = error.response;
      const { config } = error;

      if (status === 401) {
        if (!window.location.href.includes("login")) {
          window.location.href = "/login";
        }
        return;
      }

      // No CSRF-Cookie? Request one
      if (status === 419) {
        await axiosInstance(`/sanctum/csrf-cookie`, {
          method: "GET",
          baseURL: process.env.REACT_APP_BACKEND_API_URL,
        });
        // retry the request
        return axiosInstance(config);
      }

      return Promise.reject(error);
    },
  );

  return axiosInstance;
}

export const apiClient = setupApiClient();

// Return any, because we type the return in our swr hooks
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetcher = async (url: string): Promise<any> => {
  const { data } = await apiClient.get(url);
  return data;
};
