import {
  AspectRatio,
  Button,
  HStack,
  Image,
  Skeleton,
  Stack,
  StackProps,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { Picture } from "../../SWR/Types/Picture";
import {
  Carousel,
  CarouselIconButton,
  CarouselSlide,
  useCarousel,
} from "./Carousel";

interface GalleryProps {
  images: Picture[];
  onDeletePicture: (picture: Picture) => void;
  aspectRatio?: number;
  rootProps?: StackProps;
}

export default function Gallery({
  images,
  onDeletePicture,
  aspectRatio = 4 / 3,
  rootProps,
}: GalleryProps) {
  const [index, setIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesPerView = useBreakpointValue({ base: 3, md: 5 });

  const [ref, slider] = useCarousel({
    slides: {
      perView: slidesPerView,
      spacing: useBreakpointValue({ base: 16, md: 24 }),
    },
    slideChanged: (slider: any) => setCurrentSlide(slider.track.details.rel),
  });

  return (
    <Stack spacing="4" {...rootProps}>
      <AspectRatio ratio={aspectRatio}>
        <>
          <Image
            src={images[index]?.full_picture_url}
            objectFit="cover"
            alt={images[index]?.alt_text ?? "Product Image"}
            fallback={<Skeleton />}
          />
          <Button
            position="absolute"
            top="10px !important"
            right="10px !important"
            left="unset !important"
            bottom="unset !important"
            width="80px !important"
            height="45px !important"
            colorScheme="red"
            onClick={() => {
              onDeletePicture(images[index]);
            }}
          >
            Delete
          </Button>
        </>
      </AspectRatio>
      <HStack spacing="4">
        <CarouselIconButton
          onClick={() => slider.current?.prev()}
          icon={<IoChevronBackOutline />}
          aria-label="Previous slide"
          disabled={currentSlide === 0}
        />
        <Carousel ref={ref} direction="row" width="full">
          {images.map((image, i) => (
            <CarouselSlide key={i} onClick={() => setIndex(i)} cursor="pointer">
              <AspectRatio
                ratio={aspectRatio}
                transition="all 200ms"
                opacity={index === i ? 1 : 0.4}
                _hover={{ opacity: 1 }}
              >
                <Image
                  src={image.full_picture_url}
                  objectFit="cover"
                  alt={image.alt_text}
                  fallback={<Skeleton />}
                />
              </AspectRatio>
            </CarouselSlide>
          ))}
        </Carousel>
        <CarouselIconButton
          onClick={() => slider.current?.next()}
          icon={<IoChevronForwardOutline />}
          aria-label="Next slide"
          disabled={currentSlide + Number(slidesPerView) === images.length}
        />
      </HStack>
    </Stack>
  );
}
