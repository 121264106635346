import { Badge, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { FilterInput } from "../../Components/Forms/FilterInputField";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import OrderDisplayModal from "../../Components/Orders/OrderDisplayModal";
import { useCustomer } from "../../SWR/EntityHooks/UseCustomer";
import { Customer } from "../../SWR/Types/Customer";
import { OrderWithOrderedProductsAndOptions } from "../../SWR/Types/Order";

export type ShowCustomerDrawerContentProps = {
  customer: Customer;
};

export default function ShowCustomerDrawerContent({
  customer,
}: ShowCustomerDrawerContentProps) {
  const { data, isLoading, error } = useCustomer({ id: customer.id });

  const [selectedOrder, setSelectedOrder] = useState<
    OrderWithOrderedProductsAndOptions | undefined
  >(undefined);

  if (!data || isLoading || error) {
    return <MMSpinner />;
  }

  return (
    <>
      <VStack alignItems="start" spacing={5}>
        <Badge size="lg" fontSize="md" pl={3} pr={3}>
          {new Date(customer.created_at).toLocaleString()}
        </Badge>
        <HStack>
          <FilterInput
            placeholder="ID"
            label="Customer ID"
            defaultValue={data.id}
            readonly
            name="ID"
            onChange={() => {}}
          />

          <FilterInput
            placeholder="E-Mail"
            label="E-Mail"
            defaultValue={data.email}
            readonly
            name="email"
            onChange={() => {}}
          />
        </HStack>
        <Divider />
        <Text fontWeight="bold" fontSize="lg">
          Orders (click one to see details)
        </Text>
        <VStack spacing={3} alignItems="start">
          {data.orders.map((order) => {
            return (
              <HStack onClick={() => setSelectedOrder(order)} key={order.id}>
                <Badge
                  size="lg"
                  p={3}
                  variant="pill"
                  fontSize="md"
                  fontWeight="bold"
                >
                  Order #{order.id}, ${order.total_price_usd_cents / 100}
                </Badge>
                <Badge
                  size="lg"
                  fontSize="md"
                  variant="pill"
                  fontWeight="bold"
                  p={3}
                >
                  {new Date(order.created_at).toLocaleString()}
                </Badge>
              </HStack>
            );
          })}
        </VStack>
      </VStack>
      <OrderDisplayModal
        order={selectedOrder}
        isOpen={!!selectedOrder}
        onClose={() => setSelectedOrder(undefined)}
      />
    </>
  );
}
