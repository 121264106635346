import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { BaseModal } from "./BaseModal";

export type DeleteConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  description: string;
};

export default function DeleteConfirmationModal({
  isOpen,
  onClose,
  onDelete,
  description,
}: DeleteConfirmationModalProps) {
  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      header={
        <>
          <VStack
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Text color="red">Are you sure you want to proceed?</Text>
            <Text color="fg.muted" fontSize="sm">
              {description}
            </Text>
          </VStack>
        </>
      }
      showCloseButton={false}
    >
      <HStack justifyContent="center" alignItems="center">
        <Button colorScheme="red" onClick={onDelete}>
          Confirm
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </HStack>
    </BaseModal>
  );
}
