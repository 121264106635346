import useSWR from "swr";
import { Product } from "../Types/Product";

export type UseProductProps = {
  id: number;
};

export const useProduct = (props: UseProductProps) => {
  const url = props.id === 0 ? null : `/products/${props.id}`;
  const { data, error, mutate } = useSWR<Product>(url, {
    revalidateOnFocus: false,
    refreshInterval: 0,
  });

  const isLoading = !data && !error;

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};
