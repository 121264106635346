import useSWR from "swr";
import { OrderWithOrderedProductsAndOptions } from "../Types/Order";

export type UseDetailedOrderProps = {
  orderId: number;
};

export const useDetailedOrder = (props: UseDetailedOrderProps) => {
  const { data, error, mutate } = useSWR<OrderWithOrderedProductsAndOptions>(
    `/orders/${props.orderId}`,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  );

  const isLoading = !data && !error;

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};
