import { Button, Stack } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { RequestErrors } from "../../Components/ErrorHandling/RequestErrors";
import { HookForm } from "../../Components/Forms/HookForm/HookForm";
import { HookInput } from "../../Components/Forms/HookForm/HookInputField";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import { useSubmitData } from "../../Hooks/UseSubmitData";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";

const createNewProductSchema = z.object({
  name: z.string().min(3),
  sku: z.string().min(3),
  display_price_usd_cents: z.string(),
});

type CreateNewProductFormValues = z.infer<typeof createNewProductSchema>;

export type CreateNewProductDrawerContentProps = {
  onSuccess: () => void;
};

export default function CreateNewProductDrawerContent({
  onSuccess,
}: CreateNewProductDrawerContentProps) {
  const {
    onSubmit,
    error: createError,
    isLoading,
  } = useSubmitData<CreateNewProductFormValues>({
    submitMethod: (data) => apiClient.post(ApiRoutes.Products.Create, data),
    runAfterSuccess: () => {
      onSuccess();
    },
    runAfterError: () => {},
  });

  return (
    <>
      <Stack>
        <HookForm<CreateNewProductFormValues>
          onSubmit={onSubmit}
          form={{
            resolver: zodResolver(createNewProductSchema),
          }}
        >
          <Stack spacing="6">
            <HookInput name="name" label="Name" required />
            <HookInput name="sku" label="SKU" required />
            <HookInput
              name="display_price_usd_cents"
              label="Display Price (USD Cents)"
              required
            />
            <Button type="submit">Confirm</Button>
            <RequestErrors error={createError} />
          </Stack>
          {isLoading ? <MMSpinner /> : <></>}
        </HookForm>
      </Stack>
    </>
  );
}
