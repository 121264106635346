import PageHeader from "../../Components/Typography/PageHeader";
import { MenuCyclesTable } from "./MenuCyclesTable";

export const MenuCyclesIndex = () => {
  return (
    <>
      <PageHeader title="Menu Cycles" subtitle="Menu Cycles Overview" />

      <MenuCyclesTable />
    </>
  );
};
