import { Box, Heading, Stack, Text } from "@chakra-ui/react";

type PageHeaderProps = {
  title: string;
  subtitle: string;
  center?: boolean;
  subSubTitle?: string;
  hideSubtitle?: boolean;
};

export default function PageHeader({
  title,
  subtitle,
  subSubTitle,
  center,
  hideSubtitle = false,
}: PageHeaderProps) {
  return (
    <>
      <Box
        as="section"
        pt={{ base: "3", md: "6" }}
        pb={{ base: "5", md: "6" }}
        mb={5}
        borderBottomWidth="2px"
        borderBottomColor="bg.border"
        textAlign={{ base: "center", md: center ? "center" : "unset" }}
      >
        <Stack
          spacing="1"
          justifyContent={{ base: "center", md: "unset" }}
          alignItems={{ base: "center", md: "unset" }}
        >
          <Heading size={{ base: "xs", md: "sm" }} fontWeight="bold">
            {title}
          </Heading>
          {!hideSubtitle && <Text color="fg.muted">{subtitle}</Text>}
          {subSubTitle && (
            <Text color="fg.muted" fontSize="sm">
              {subSubTitle}
            </Text>
          )}
        </Stack>
      </Box>
    </>
  );
}
