import {
  Avatar,
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { Pagination } from "../../Components/Pagination/pagination";
import { usePagination } from "../../Hooks/UsePagination";
import { useProductsIndex } from "../../SWR/EntityHooks/UseProductsIndex";
import { Stack } from "@chakra-ui/react";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import { GrConfigure } from "react-icons/gr";
import { BaseDrawer } from "../../Components/Drawer/BaseDrawer";
import PageHeader from "../../Components/Typography/PageHeader";
import CreateNewProductDrawerContent from "./CreateNewProductDrawerContent";
import { Product } from "../../SWR/Types/Product";
import { useMemo, useState } from "react";
import UpdateProductDrawerContent from "./UpdateProductDrawerContent";
import UpdateProductProductConfigurationOptionsDrawerContent from "./UpdateProductProductConfigurationOptionsDrawerContent";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";
import DeleteConfirmationModal from "../../Components/Modals/DeleteConfirmationModal";
import { FilterInput } from "../../Components/Forms/FilterInputField";
import debounce from "lodash.debounce";

export const ProductsTable = () => {
  const { currentPage, handleNext, handlePrev, setCurrentPage } =
    usePagination();

  const [search, setSearch] = useState("");

  const { data, error, isLoading, mutate } = useProductsIndex({
    page: currentPage,
    perPage: 10,
    search: search,
  });

  const toast = useToast();

  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(
    undefined,
  );

  const [selectedProductToDelete, setSelectedProductToDelete] = useState<
    Product | undefined
  >(undefined);

  const createNewProduct = useDisclosure();
  const updateProduct = useDisclosure();
  const updateProductProductConfigurationOptions = useDisclosure();

  const onDelete = () => {
    apiClient
      .delete(ApiRoutes.Products.Delete(selectedProductToDelete?.id ?? 0))
      .then(() => {
        setSelectedProductToDelete(undefined);
        toast({
          title: "Product deleted!",
          status: "success",
          duration: 2000,
        });
        mutate();
      });
  };

  const onSuccessfullyCreated = () => {
    createNewProduct.onClose();
    toast({
      title: "Created!",
      status: "success",
      duration: 2000,
    });
    mutate();
  };

  const debouncedProductSearch = useMemo(() => {
    const handleChanged = (e: string) => {
      setCurrentPage(1);
      setSearch(e);
    };

    return debounce(handleChanged, 500);
  }, [setCurrentPage]);

  return (
    <>
      <Box
        bg="bg.surface"
        boxShadow={{ base: "none", md: "sm" }}
        borderRadius={{ base: "none", md: "lg" }}
      >
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
            >
              <Text textStyle="lg" fontWeight="medium">
                Products
              </Text>
              <HStack alignItems="end">
                <FilterInput
                  name="search"
                  label="Search"
                  type="string"
                  showLabel={false}
                  onChange={debouncedProductSearch}
                  placeholder="Search for a product"
                />
                <Button onClick={createNewProduct.onOpen}>Create New</Button>
              </HStack>
            </Stack>
            {(error || isLoading || !data) && <MMSpinner />}
          </Box>
          <Box overflowX="auto">
            <Table>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>SKU</Th>
                  <Th>Display Price</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.data.map((product) => (
                  <Tr key={product.id}>
                    <Td>
                      <HStack spacing="3">
                        <Avatar
                          name={product.sku}
                          src={
                            product.pictures.length > 0
                              ? product.pictures[0].full_picture_url
                              : ""
                          }
                          boxSize="10"
                        />
                        <Box>
                          <Text fontWeight="medium">{product.name}</Text>
                        </Box>
                      </HStack>
                    </Td>
                    <Td>
                      <Badge size="sm" colorScheme="blue">
                        {product.sku}
                      </Badge>
                    </Td>
                    <Td>
                      <Badge size="sm" colorScheme="green">
                        ${product.display_price_usd_cents / 100}
                      </Badge>
                    </Td>
                    <Td>
                      <HStack spacing="1">
                        <IconButton
                          icon={<FiTrash2 />}
                          variant="tertiary"
                          aria-label="Delete"
                          onClick={() => {
                            setSelectedProductToDelete(product);
                          }}
                        />
                        <IconButton
                          icon={<FiEdit2 />}
                          variant="tertiary"
                          aria-label="Edit"
                          onClick={() => {
                            setSelectedProduct(product);
                            updateProduct.onOpen();
                          }}
                        />
                        <IconButton
                          icon={<GrConfigure />}
                          variant="tertiary"
                          aria-label="Configurations"
                          onClick={() => {
                            setSelectedProduct(product);
                            updateProductProductConfigurationOptions.onOpen();
                          }}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box px={{ base: "4", md: "6" }} pb="5">
            <Pagination
              currentPage={currentPage}
              totalPages={data?.last_page ?? 1}
              handleNext={() => {
                handleNext(data?.last_page ?? 1);
              }}
              handlePrevious={handlePrev}
            />
          </Box>
        </Stack>
      </Box>
      <BaseDrawer
        isOpen={createNewProduct.isOpen}
        onClose={createNewProduct.onClose}
        size="md"
        header={
          <>
            <Box p={3} mt={3}>
              <PageHeader
                center
                title="Create a new product"
                subtitle="Easy going!"
              />
            </Box>
          </>
        }
        placement="right"
        showCloseButton
      >
        <CreateNewProductDrawerContent onSuccess={onSuccessfullyCreated} />
      </BaseDrawer>
      {selectedProduct && (
        <BaseDrawer
          isOpen={updateProduct.isOpen}
          onClose={updateProduct.onClose}
          size="md"
          header={
            <>
              <Box p={3} mt={3}>
                <PageHeader
                  center
                  title="Update the product"
                  subtitle="Easy going!"
                />
              </Box>
            </>
          }
          placement="right"
          showCloseButton
        >
          <UpdateProductDrawerContent
            product={selectedProduct}
            onPictureDeleted={() => {
              mutate();
            }}
            onSuccess={() => {
              mutate();
            }}
            onPictureUploaded={() => {
              mutate();
            }}
          />
        </BaseDrawer>
      )}
      {selectedProduct && (
        <BaseDrawer
          isOpen={updateProductProductConfigurationOptions.isOpen}
          onClose={updateProductProductConfigurationOptions.onClose}
          size="md"
          header={
            <>
              <Box p={3} mt={3}>
                <PageHeader
                  center
                  title="Update the product options and prices"
                  subtitle="Easy going!"
                />
              </Box>
            </>
          }
          placement="right"
          showCloseButton
        >
          <UpdateProductProductConfigurationOptionsDrawerContent
            product={selectedProduct}
          />
        </BaseDrawer>
      )}
      {selectedProductToDelete && (
        <DeleteConfirmationModal
          isOpen={selectedProductToDelete !== undefined}
          onClose={() => {
            setSelectedProductToDelete(undefined);
          }}
          onDelete={onDelete}
          description="The product will be removed from the system."
        />
      )}
    </>
  );
};
