import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import PageHeader from "../../Components/Typography/PageHeader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { ApiRoutes } from "../../SWR/ApiRoutes";
import { apiClient } from "../../SWR/ApiClient";

export default function ExportsIndex() {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const handleExport = () => {
    const start = startDate ? startDate.toISOString().split("T")[0] : "";
    const end = endDate ? endDate.toISOString().split("T")[0] : "";

    apiClient
      .get(ApiRoutes.Export, {
        params: {
          start,
          end,
        },
        responseType: "blob",
      })
      .then((response) => {
        const filename = `orders${
          start && end
            ? `_${start}-${end}`
            : start
              ? `_${start}-`
              : `_-$
        {end}`
        }.xlsx`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <PageHeader title="Data Export" subtitle="Export Orders and Shipments" />

      <Box
        width="full"
        height="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <HStack width="full" justifyContent="center" alignItems="end">
          <VStack>
            <Text textAlign="left" width="full">
              Start Date
            </Text>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <Text textAlign="left" width="full">
              End Date
            </Text>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </VStack>
          <Button onClick={handleExport}>Export</Button>
        </HStack>
      </Box>
    </>
  );
}
