import { FormControl, Select } from "@chakra-ui/react";
import { CommonHookFormFieldProps } from "./HookForm/Types";

export const FilterSelect = ({
  name,
  label,
  options,
  defaultValue,
  onChange,
}: CommonHookFormFieldProps & {
  options: { label: string; value: string | number }[];
  onChange: (arg0: React.ChangeEvent<HTMLSelectElement>) => void;
}) => {
  return (
    <FormControl width={{ base: "full", md: "unset" }}>
      <Select
        borderColor="gray"
        id={name}
        placeholder={label}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
