import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { FieldValues, SubmitHandler } from "react-hook-form";

type UseSubmitDataProps<T> = {
  submitMethod: (data: T) => Promise<AxiosResponse> | Error;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  runAfterSuccess?: (data?: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  runAfterError?: (data?: any) => any;
  refreshTimeOut?: number;
};

type UseSubmitData<T extends FieldValues> = {
  success: boolean;
  error: AxiosError | null;
  isLoading: boolean;
  onSubmit: SubmitHandler<T>;
};

export const useSubmitData = <T extends FieldValues>({
  submitMethod,
  runAfterSuccess,
  runAfterError,
  refreshTimeOut = 3000,
}: UseSubmitDataProps<T>): UseSubmitData<T> => {
  const [error, setError] = useState<AxiosError | null>(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const timeout = useRef(0);

  useEffect(() => {
    timeout.current = window.setTimeout(() => {
      setSuccess(false);
    }, refreshTimeOut);

    return () => {
      window.clearTimeout(timeout.current);
    };
  }, [refreshTimeOut, success]);

  const onSubmit: SubmitHandler<T> = async (data) => {
    setIsLoading(true);

    try {
      await submitMethod(data as T);
      setSuccess(true);
      setError(null);

      if (runAfterSuccess) {
        runAfterSuccess();
      }
    } catch (error) {
      setSuccess(false);
      setError(error as AxiosError);

      if (runAfterError) {
        runAfterError(error);
      }
    }

    setIsLoading(false);
  };

  return { error, isLoading, onSubmit, success };
};
