import useSWR from "swr";
import { DashboardStats } from "../Types/DashboardStats";

export const useDashboardStats = () => {
  const url = `/dashboard/stats`;
  const { data, error, mutate } = useSWR<DashboardStats>(url, {
    revalidateOnFocus: false,
    refreshInterval: 0,
  });

  const isLoading = !data && !error;

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};
