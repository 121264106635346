import {
  Badge,
  Box,
  HStack,
  IconButton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaEye } from "react-icons/fa";
import { BaseDrawer } from "../../Components/Drawer/BaseDrawer";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import { Pagination } from "../../Components/Pagination/pagination";
import PageHeader from "../../Components/Typography/PageHeader";
import { usePagination } from "../../Hooks/UsePagination";
import { useCustomersIndex } from "../../SWR/EntityHooks/UseCustomersIndex";
import { Customer } from "../../SWR/Types/Customer";
import ShowCustomerDrawerContent from "./ShowCustomerDrawerContent";

export default function CustomersTable() {
  const { currentPage, handleNext, handlePrev } = usePagination();

  const { data, error, isLoading } = useCustomersIndex({
    page: currentPage,
    perPage: 10,
  });

  const showCustomer = useDisclosure();
  const [selectedCustomer, setSelectedCustomer] = useState<
    Customer | undefined
  >(undefined);

  return (
    <>
      <Box
        bg="bg.surface"
        boxShadow={{ base: "none", md: "sm" }}
        borderRadius={{ base: "none", md: "lg" }}
      >
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
            >
              <Text textStyle="lg" fontWeight="medium">
                Customers
              </Text>
            </Stack>
            {(error || isLoading || !data) && <MMSpinner />}
          </Box>
          <Box overflowX="auto">
            <Table>
              <Thead>
                <Tr>
                  <Th>Created At</Th>
                  <Th>E-Mail</Th>
                  <Th>Orders</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.data.map((customer) => (
                  <Tr key={customer.id}>
                    <Td>
                      <Badge size="sm" colorScheme="blue">
                        {new Date(customer.created_at).toLocaleString()}
                      </Badge>
                    </Td>
                    <Td>
                      <Badge size="sm" colorScheme="blue">
                        {customer.email}
                      </Badge>
                    </Td>
                    <Td>
                      <Badge size="sm" colorScheme="blue">
                        {customer.orders_count}
                      </Badge>
                    </Td>
                    <Td>
                      <HStack spacing="1">
                        <IconButton
                          icon={<FaEye />}
                          variant="tertiary"
                          aria-label="View"
                          onClick={() => {
                            setSelectedCustomer(customer);
                            showCustomer.onOpen();
                          }}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box px={{ base: "4", md: "6" }} pb="5">
            <Pagination
              currentPage={currentPage}
              totalPages={data?.last_page ?? 1}
              handleNext={() => {
                handleNext(data?.last_page ?? 1);
              }}
              handlePrevious={handlePrev}
            />
          </Box>
        </Stack>
      </Box>
      {selectedCustomer && (
        <BaseDrawer
          isOpen={showCustomer.isOpen}
          onClose={showCustomer.onClose}
          size="md"
          header={
            <>
              <Box p={3} mt={3}>
                <PageHeader
                  center
                  title="Information about your customer"
                  subtitle="Details, orders"
                />
              </Box>
            </>
          }
          placement="right"
          showCloseButton
        >
          <ShowCustomerDrawerContent customer={selectedCustomer} />
        </BaseDrawer>
      )}
    </>
  );
}
