import { ChakraProvider } from "@chakra-ui/react";
import { macroMealsTheme } from "./ChakraUI/Theme";
import AuthenticatedLayout from "./Layout/AuthenticatedLayout";
import { ProductsIndex } from "./Pages/Products/Index";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SWRConfig } from "swr";
import { fetcher } from "./SWR/ApiClient";
import { ProductConfigurationsIndex } from "./Pages/ProductConfigurations/Index";
import CustomersIndex from "./Pages/Customers/Index";
import { OrdersIndex } from "./Pages/Orders/Index";
import PicturesIndex from "./Pages/SEO/Pictures/Index";
import { ProductCategoriesIndex } from "./Pages/ProductCategories/Index";
import { MenuCyclesIndex } from "./Pages/MenuCycles/Index";
import LoginIndex from "./Pages/Login/Index";
import ExportsIndex from "./Pages/Exports/Index";
import DashboardStatsIndex from "./Pages/DashboardStats/Index";

function App() {
  return (
    <SWRConfig
      value={{
        refreshInterval: 10000,
        fetcher: fetcher,
      }}
    >
      <ChakraProvider theme={macroMealsTheme}>
        <div className="App">
          <Router>
            <Routes>
              <Route path="/login" element={<LoginIndex />} />
              <Route path="/" element={<AuthenticatedLayout />}>
                <Route path="/" element={<DashboardStatsIndex />} />
                <Route path="/products" element={<ProductsIndex />} />
                <Route
                  path="/product-categories"
                  element={<ProductCategoriesIndex />}
                />
                <Route path="/menu-cycles" element={<MenuCyclesIndex />} />
                <Route path="/customers" element={<CustomersIndex />} />
                <Route path="/orders" element={<OrdersIndex />} />
                <Route path="/exports" element={<ExportsIndex />} />
                <Route path="/seo/pictures" element={<PicturesIndex />} />
                <Route
                  path="/configurations"
                  element={<ProductConfigurationsIndex />}
                />
              </Route>
            </Routes>
          </Router>
        </div>
      </ChakraProvider>
    </SWRConfig>
  );
}

export default App;
