import useSWR from "swr";
import { Product } from "../Types/Product";

export type UseSearchProductsForCategory = {
  search: string;
  productCategoryId: number;
};

export const useSearchProductsForCategory = (
  props: UseSearchProductsForCategory = { productCategoryId: 0, search: "" },
) => {
  const url =
    props.search.length <= 0 || props.productCategoryId <= 0
      ? null
      : `/product-categories/${props.productCategoryId}/available-products?search=${props.search}`;

  const { data, error, mutate } = useSWR<Product[]>(url, {
    revalidateOnFocus: false,
    refreshInterval: 30000,
  });

  const isLoading = !data && !error && url !== null;

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};
