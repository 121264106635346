import {
  Avatar,
  Box,
  Button,
  HStack,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { z } from "zod";
import { HookForm } from "../../../Components/Forms/HookForm/HookForm";
import { HookInput } from "../../../Components/Forms/HookForm/HookInputField";
import MMSpinner from "../../../Components/Indicators/MMSpinner";
import { Pagination } from "../../../Components/Pagination/pagination";
import { usePagination } from "../../../Hooks/UsePagination";
import { useSubmitData } from "../../../Hooks/UseSubmitData";
import { apiClient } from "../../../SWR/ApiClient";
import { ApiRoutes } from "../../../SWR/ApiRoutes";
import { usePicturesIndex } from "../../../SWR/EntityHooks/UsePicturesIndex";

const updatePictureAltTextSchema = z.object({
  alt_text: z.string().min(3),
});

type UpdatePictureAltTextSchema = z.infer<typeof updatePictureAltTextSchema>;

export const PicturesTable = () => {
  const { currentPage, handleNext, handlePrev } = usePagination();

  const { data, error, isLoading, mutate } = usePicturesIndex({
    page: currentPage,
    perPage: 10,
  });

  const [pictureToUpdateId, setPictureToUpdateId] = useState<
    number | undefined
  >();

  const toast = useToast();

  const { onSubmit: onSubmitUpdate } =
    useSubmitData<UpdatePictureAltTextSchema>({
      submitMethod: (data) =>
        apiClient.put(
          ApiRoutes.Pictures.UpdateAltText(pictureToUpdateId ?? 0),
          data,
        ),
      runAfterSuccess: () => {
        mutate();
        toast({
          title: "Alt Text Updated",
          status: "success",
        });
      },
    });

  return (
    <>
      <Box
        bg="bg.surface"
        boxShadow={{ base: "none", md: "sm" }}
        borderRadius={{ base: "none", md: "lg" }}
      >
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
            >
              <Text textStyle="lg" fontWeight="medium">
                Pictures
              </Text>
            </Stack>
            {(error || isLoading || !data) && <MMSpinner />}
          </Box>
          <Box overflowX="auto">
            <Table>
              <Thead>
                <Tr>
                  <Th>Picture</Th>
                  <Th>Alt Text</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.data.map((picture) => (
                  <Tr key={picture.id}>
                    <Td>
                      <HStack spacing="3">
                        <Avatar
                          size="lg"
                          name={picture.alt_text}
                          src={picture.full_picture_url}
                        />
                      </HStack>
                    </Td>
                    <Td>
                      <HookForm<UpdatePictureAltTextSchema>
                        form={{
                          resolver: zodResolver(updatePictureAltTextSchema),
                        }}
                        onSubmit={onSubmitUpdate}
                      >
                        <HStack alignItems="end">
                          <HookInput
                            label="Alt Text"
                            name="alt_text"
                            defaultValue={picture.alt_text}
                          />
                          <Button
                            type="submit"
                            onClick={() => {
                              setPictureToUpdateId(picture.id);
                            }}
                          >
                            Update
                          </Button>
                        </HStack>
                      </HookForm>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box px={{ base: "4", md: "6" }} pb="5">
            <Pagination
              currentPage={currentPage}
              totalPages={data?.last_page ?? 1}
              handleNext={() => {
                handleNext(data?.last_page ?? 1);
              }}
              handlePrevious={handlePrev}
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
};
