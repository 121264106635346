import { Button, Stack, useDisclosure, useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { RequestErrors } from "../../Components/ErrorHandling/RequestErrors";
import { HookForm } from "../../Components/Forms/HookForm/HookForm";
import { HookInput } from "../../Components/Forms/HookForm/HookInputField";
import { HookSwitch } from "../../Components/Forms/HookForm/HookSwitch";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import DeleteConfirmationModal from "../../Components/Modals/DeleteConfirmationModal";
import { useSubmitData } from "../../Hooks/UseSubmitData";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";
import { useMenuCycle } from "../../SWR/EntityHooks/UseMenuCycle";
import { MenuCycle } from "../../SWR/Types/MenuCycle";

type UpdateMenuCycleDrawerContent = {
  menuCycle?: MenuCycle;
  onSuccess: () => void;
};

const updateMenuCycleSchema = z.object({
  name: z.string().min(3),
  active: z.boolean(),
  active_next: z.boolean(),
});

type UpdateMenuCycleFormValues = z.infer<typeof updateMenuCycleSchema>;

export default function UpdateProductCategoryDrawerContent({
  menuCycle,
  onSuccess,
}: UpdateMenuCycleDrawerContent) {
  const toast = useToast();

  const {
    data: fetchedMenuCycle,
    error: fetchedMenuCycleError,
    isLoading: fetchMenuCycleLoading,
    mutate,
  } = useMenuCycle({
    id: menuCycle?.id ?? 0,
  });

  const {
    onSubmit,
    error: updateError,
    isLoading,
  } = useSubmitData<UpdateMenuCycleFormValues>({
    submitMethod: (data) =>
      apiClient.put(ApiRoutes.MenuCycles.Update(menuCycle?.id ?? 0), data),
    runAfterSuccess: () => {
      toast({
        title: "Updated!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      onSuccess();
      mutate();
    },
    runAfterError: () => {},
  });

  const showSaveWarning = useDisclosure();

  if (!fetchedMenuCycle || fetchMenuCycleLoading || fetchedMenuCycleError) {
    return <MMSpinner />;
  }

  return (
    <>
      <Stack>
        <HookForm<UpdateMenuCycleFormValues>
          onSubmit={onSubmit}
          form={{
            resolver: zodResolver(updateMenuCycleSchema),
          }}
        >
          <Stack spacing="6">
            <HookInput
              name="name"
              label="Name"
              required
              defaultValue={fetchedMenuCycle.name}
            />
            <HookSwitch
              name="active"
              label="Active Cycle"
              defaultChecked={fetchedMenuCycle.active}
            />
            <HookSwitch
              name="active_next"
              label="Active Next Cycle"
              defaultChecked={fetchedMenuCycle.active_next}
            />
            <Button id="submit-btn" type="submit" display="none"></Button>
            <Button onClick={showSaveWarning.onOpen}>Confirm</Button>
            <RequestErrors error={updateError} />
          </Stack>
          {isLoading ? <MMSpinner /> : <></>}
        </HookForm>
      </Stack>
      <DeleteConfirmationModal
        onClose={showSaveWarning.onClose}
        isOpen={showSaveWarning.isOpen}
        onDelete={() => {
          showSaveWarning.onClose();
          document.getElementById("submit-btn")?.click();
        }}
        description="Changed information about cycles (active, next) will change display for customers in the shop."
      />
    </>
  );
}
