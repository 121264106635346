import { AxiosError } from "axios";
import { Text } from "@chakra-ui/react";
import { Fragment } from "react";

type RequestErrorsProps = {
  error?: AxiosError | null;
};

const renderErrorMessages = (
  error: RequestErrorsProps["error"],
): JSX.Element | JSX.Element[] => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.values((error?.response as any).data.errors).map((error) => (
      <Fragment key={error as string}>
        <span>{error as string}</span>
        <br />
      </Fragment>
    ));
  } catch {
    return <span>{error?.response?.statusText}</span>;
  }
};

export function RequestErrors({
  error,
}: RequestErrorsProps): JSX.Element | null {
  if (!error) {
    return null;
  }

  return (
    <Text
      color="red.500"
      fontSize="xs"
      textTransform="uppercase"
      fontWeight="bold"
    >
      {renderErrorMessages(error)}
    </Text>
  );
}
