import {
  Flex,
  useColorModeValue,
  useDisclosure,
  Icon,
  Box,
  Collapse,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  IconButton,
  InputGroup,
  InputLeftElement,
  Input,
  useColorMode,
  HStack,
} from "@chakra-ui/react";

import {
  MdCategory,
  MdHome,
  MdKeyboardArrowRight,
  MdMenuBook,
} from "react-icons/md";
import {
  FaProductHunt,
  FaCashRegister,
  FaFileExcel,
  FaDoorOpen,
} from "react-icons/fa";
import { BsGearFill } from "react-icons/bs";
import { FiMenu, FiSearch } from "react-icons/fi";
import { GrConfigure } from "react-icons/gr";
import Logo from "../Logo/Logo";
import { IoPulseOutline } from "react-icons/io5";
import { AiFillPicture } from "react-icons/ai";
import { CiLight, CiDark } from "react-icons/ci";
import { PiUserList } from "react-icons/pi";
import {
  Link as ReactRouterLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAuth } from "../SWR/EntityHooks/UseAuth";
import { useEffect } from "react";

export default function AuthenticatedLayout() {
  const sidebar = useDisclosure();
  const products = useDisclosure();
  const seo = useDisclosure();
  const color = useColorModeValue("gray.600", "gray.300");
  const { colorMode, toggleColorMode } = useColorMode();

  const { data, mutate, isLoading } = useAuth();

  const onLogout = async () => {
    localStorage.removeItem("key");
    await mutate();
    window.location.href = "/login";
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const isAuthenticated = async () => {
      await mutate();
      if (data?.authenticated) {
        return true;
      }
      return false;
    };

    async function check() {
      if (!(await isAuthenticated())) {
        navigate("/login");
      }
    }
    check();
  }, [isLoading, location, navigate, data, mutate]);

  const NavItem = (props: any) => {
    const { icon, children, ...rest } = props;
    return (
      <ReactRouterLink to={props.to}>
        <Flex
          align="center"
          px="4"
          pl="4"
          py="3"
          cursor="pointer"
          color="inherit"
          _dark={{
            color: "gray.400",
          }}
          _hover={{
            bg: "gray.100",
            _dark: {
              bg: "gray.900",
            },
            color: "gray.900",
          }}
          role="group"
          fontWeight="semibold"
          transition=".15s ease"
          {...rest}
        >
          {icon && (
            <Icon
              mx="2"
              boxSize="4"
              _groupHover={{
                color: color,
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </ReactRouterLink>
    );
  };

  const SidebarContent = (props: any) => (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="white"
      _dark={{
        bg: "gray.800",
      }}
      border
      color="inherit"
      borderRightWidth="1px"
      w="60"
      {...props}
    >
      <Flex px="4" py="5" justifyContent="center" alignItems="center">
        <Logo />
      </Flex>
      <Flex
        direction="column"
        as="nav"
        fontSize="sm"
        color="gray.600"
        aria-label="Main Navigation"
      >
        <NavItem icon={MdHome} to="/">
          Dashboard
        </NavItem>
        <NavItem icon={FaProductHunt} onClick={products.onToggle}>
          Products
          <Icon as={MdKeyboardArrowRight} ml="auto" />
        </NavItem>
        <Collapse in={products.isOpen}>
          <NavItem pl="12" py="2" icon={FaProductHunt} to="/products">
            All Products
          </NavItem>
          <NavItem pl="12" py="2" icon={GrConfigure} to="/configurations">
            Configurations
          </NavItem>
          <NavItem pl="12" py="2" icon={MdCategory} to="/product-categories">
            Categories
          </NavItem>
        </Collapse>
        <NavItem icon={MdMenuBook} to="/menu-cycles">
          Menu Cycles
        </NavItem>
        <NavItem icon={PiUserList} to="/customers">
          Customers
        </NavItem>
        <NavItem icon={FaCashRegister} to="/orders">
          Orders
        </NavItem>
        <NavItem icon={IoPulseOutline} onClick={seo.onToggle}>
          SEO
          <Icon as={MdKeyboardArrowRight} ml="auto" />
        </NavItem>
        <Collapse in={seo.isOpen}>
          <NavItem pl="12" py="2" icon={AiFillPicture} to="/seo/pictures">
            Pictures
          </NavItem>
        </Collapse>
        <NavItem icon={FaFileExcel} to="/exports">
          Data Export
        </NavItem>
      </Flex>
    </Box>
  );

  return (
    <Box
      as="section"
      bg="gray.50"
      _dark={{
        bg: "gray.700",
      }}
      minH="100vh"
    >
      <SidebarContent
        display={{
          base: "none",
          md: "unset",
        }}
      />
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent w="full" borderRight="none" />
        </DrawerContent>
      </Drawer>
      <Box
        ml={{
          base: 0,
          md: 60,
        }}
        transition=".3s ease"
      >
        <Flex
          as="header"
          align="center"
          justify="space-between"
          w="full"
          px="4"
          bg="white"
          _dark={{
            bg: "gray.800",
          }}
          borderBottomWidth="1px"
          color="inherit"
          h="14"
        >
          <IconButton
            aria-label="Menu"
            display={{
              base: "inline-flex",
              md: "none",
            }}
            onClick={sidebar.onOpen}
            icon={<FiMenu />}
            size="sm"
          />
          <InputGroup
            w="96"
            display={{
              base: "none",
              md: "flex",
            }}
          >
            <InputLeftElement color="gray.500">
              <FiSearch />
            </InputLeftElement>
            <Input placeholder="Search anything! (Sorry, this doesn't work yet!)" />
          </InputGroup>
          <HStack>
            <IconButton
              aria-label="Logout"
              icon={<FaDoorOpen />}
              onClick={onLogout}
              size="sm"
            />

            {colorMode === "dark" ? (
              <IconButton
                aria-label="Switch Color Mode"
                icon={<CiLight />}
                onClick={toggleColorMode}
                size="sm"
              />
            ) : (
              <IconButton
                aria-label="Switch Color Mode"
                onClick={toggleColorMode}
                icon={<CiDark />}
                size="sm"
              />
            )}
          </HStack>
        </Flex>

        <Box as="main" p={{ base: 1, sm: 4 }}>
          <Box p={{ base: 1, sm: 3 }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
