import useSWR from "swr";
import { ApiRoutes } from "../ApiRoutes";
import { ProductConfiguration } from "../Types/ProductConfiguration";
import { ProductProductConfigurationOption } from "../Types/ProductConfigurationOption";

export type UseProductProductConfigurationOptionsProps = {
  productId: number;
};

export type TransformedProductProductConfigurationOption = {
  configuration: ProductConfiguration;
  options: ProductProductConfigurationOption[];
};

const transformToReadable = (data: ProductProductConfigurationOption[]) => {
  const configurationsAndOptions: TransformedProductProductConfigurationOption[] =
    [];

  data.forEach((option) => {
    if (
      !configurationsAndOptions.find(
        (e) => e.configuration.id === option.product_configuration.id,
      )
    ) {
      configurationsAndOptions.push({
        configuration: option.product_configuration,
        options: [],
      });
    }

    const entry = configurationsAndOptions.find(
      (e) => e.configuration.id === option.product_configuration.id,
    );

    if (!entry) {
      return;
    }

    entry.options.push(option);
  });

  return configurationsAndOptions;
};

export const useProductProductConfigurationOptionsIndex = (
  props: UseProductProductConfigurationOptionsProps,
) => {
  const { data, error, mutate } = useSWR<ProductProductConfigurationOption[]>(
    ApiRoutes.Products.ProductConfigurationOptions.Index(props.productId),
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  );

  const isLoading = !data && !error;

  const transformed = () => {
    if (!data) {
      return [];
    }
    return transformToReadable(data);
  };

  return {
    data,
    isLoading,
    error,
    mutate,
    transformed: transformed(),
  };
};
