import PageHeader from "../../Components/Typography/PageHeader";
import { ProductConfigurationsTable } from "./ProductConfigurationsTable";

export const ProductConfigurationsIndex = () => {
  return (
    <>
      <PageHeader
        title="Configurations"
        subtitle="Configurations that you can attach to your products"
      />
      <ProductConfigurationsTable />
    </>
  );
};
