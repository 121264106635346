import useSWR from "swr";

export const useAuth = () => {
  const { data, error, mutate, isLoading } = useSWR<{
    authenticated: boolean;
  }>("/check-auth", {
    revalidateOnFocus: true,
    refreshInterval: 0,
  });

  return {
    data,
    error,
    mutate,
    isLoading,
  };
};
