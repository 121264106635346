import PageHeader from "../../Components/Typography/PageHeader";
import { ProductsTable } from "./ProductsTable";

export const ProductsIndex = () => {
  return (
    <>
      <PageHeader title="Products" subtitle="Your Product Overview" />
      <ProductsTable />
    </>
  );
};
