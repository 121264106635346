import { ProductConfigurationOption } from "../Types/ProductConfigurationOption";
import useSWR from "swr";
import { ApiRoutes } from "../ApiRoutes";

export type UseProductConfigurationOptionsProps = {
  productConfigurationId: number;
};

export const useProductConfigurationOptions = (
  props: UseProductConfigurationOptionsProps,
) => {
  const { data, error, mutate } = useSWR<ProductConfigurationOption[]>(
    ApiRoutes.ProductConfigurationOptions.Index(props.productConfigurationId),
  );

  const isLoading = !data && !error;

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};
