import useSWR from "swr";
import { PaginatedResponse } from "../Types/PaginatedResponse";
import { ProductCategory } from "../Types/ProductCategory";

export type UseProductCategoriesIndexProps = {
  page: number;
  perPage: number;
};

export const useProductCategoriesIndex = (
  props: UseProductCategoriesIndexProps = { page: 1, perPage: 10 },
) => {
  const { data, error, mutate } = useSWR<PaginatedResponse<ProductCategory>>(
    `/product-categories?page=${props.page}&per_page=${props.perPage}`,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  );

  // Cache Call
  useSWR<PaginatedResponse<ProductCategory>>(
    `/products?page=${props.page + 1}&per_page=${props.perPage}`,
  );

  const isLoading = !data && !error;

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};
