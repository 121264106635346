import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { ReactNode } from "react";

export type DerivedModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  header: ReactNode;
  showCloseButton?: boolean;
};

export const BaseModal = ({
  isOpen,
  onClose,
  children,
  header,
  showCloseButton = true,
}: ModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent
        borderRadius="xl"
        mx={{ base: "2.5", lg: "16" }}
        overflow="hidden"
      >
        <ModalHeader>{header}</ModalHeader>
        {showCloseButton && <ModalCloseButton />}
        <ModalBody p={{ base: "2", md: "3", lg: "5" }}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
