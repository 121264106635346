import {
  Avatar,
  Box,
  Button,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { Stack } from "@chakra-ui/react";
import MMSpinner from "../../Components/Indicators/MMSpinner";
import { useProductConfigurationsIndex } from "../../SWR/EntityHooks/UseProductConfigurationsIndex";
import { BaseDrawer } from "../../Components/Drawer/BaseDrawer";
import PageHeader from "../../Components/Typography/PageHeader";
import CreateNewProductConfigurationDrawerContent from "./CreateNewProductConfigurationDrawerContent";
import { useState } from "react";
import { ProductConfiguration } from "../../SWR/Types/ProductConfiguration";
import UpdateProductConfigurationDrawerContent from "./UpdateProductConfigurationDrawerContent";
import DeleteConfirmationModal from "../../Components/Modals/DeleteConfirmationModal";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";
import { IoIosOptions } from "react-icons/io";
import ProductConfigurationOptionsDrawerContent from "./ProductConfigurationOptionsDrawerContent";

export const ProductConfigurationsTable = () => {
  const [selectedProductConfiguration, setSelectedProductConfiguration] =
    useState<ProductConfiguration | undefined>(undefined);

  const [
    selectedProductConfigurationToDelete,
    setSelectedProductConfigurationToDelete,
  ] = useState<ProductConfiguration | undefined>(undefined);

  const toast = useToast();

  const createNewProductConfiguration = useDisclosure();
  const updateProductConfiguration = useDisclosure();
  const updateProductConfigurationOptions = useDisclosure();

  const { data, error, isLoading, mutate } = useProductConfigurationsIndex();

  const onDeleteProductConfiguration = () => {
    apiClient
      .delete(
        ApiRoutes.ProductConfigurations.Delete(
          selectedProductConfigurationToDelete?.id ?? 0,
        ),
      )
      .then(() => {
        setSelectedProductConfigurationToDelete(undefined);
        toast({
          title: "Configuration was deleted!",
          status: "success",
          duration: 2000,
        });
        mutate();
      });
  };

  const onSuccessfullyCreated = () => {
    createNewProductConfiguration.onClose();
    toast({
      title: "Created!",
      status: "success",
      duration: 2000,
    });
    mutate();
  };

  const onSuccessfullyUpdated = () => {
    toast({
      title: "Updated!",
      status: "success",
      duration: 2000,
    });
    mutate();
  };

  return (
    <>
      <Box
        bg="bg.surface"
        boxShadow={{ base: "none", md: "sm" }}
        borderRadius={{ base: "none", md: "lg" }}
      >
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
            >
              <Text textStyle="lg" fontWeight="medium">
                Configurations
              </Text>
              <Button onClick={createNewProductConfiguration.onOpen}>
                Create New
              </Button>
            </Stack>
            {(error || isLoading || !data) && <MMSpinner />}
          </Box>
          <Box overflowX="auto">
            <Table>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((productConfiguration) => (
                  <Tr key={productConfiguration.id}>
                    <Td>
                      <HStack spacing="3">
                        <Avatar name={productConfiguration.name} boxSize="10" />
                        <Box>
                          <Text fontWeight="medium">
                            {productConfiguration.name}
                          </Text>
                        </Box>
                      </HStack>
                    </Td>
                    <Td>
                      <HStack spacing="1">
                        <IconButton
                          icon={<FiTrash2 />}
                          variant="tertiary"
                          aria-label="Delete"
                          onClick={() => {
                            setSelectedProductConfigurationToDelete(
                              productConfiguration,
                            );
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            setSelectedProductConfiguration(
                              productConfiguration,
                            );
                            updateProductConfiguration.onOpen();
                          }}
                          icon={<FiEdit2 />}
                          variant="tertiary"
                          aria-label="Edit"
                        />
                        <IconButton
                          onClick={() => {
                            setSelectedProductConfiguration(
                              productConfiguration,
                            );
                            updateProductConfigurationOptions.onOpen();
                          }}
                          icon={<IoIosOptions />}
                          variant="tertiary"
                          aria-label="Options"
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Stack>
      </Box>
      <BaseDrawer
        isOpen={updateProductConfigurationOptions.isOpen}
        onClose={updateProductConfigurationOptions.onClose}
        size="md"
        header={
          <>
            <Box p={3} mt={3}>
              <PageHeader
                center
                title="Update the options of a configuration"
                subtitle="Easy going!"
              />
            </Box>
          </>
        }
        placement="right"
        showCloseButton
      >
        <ProductConfigurationOptionsDrawerContent
          productConfiguration={selectedProductConfiguration}
        />
      </BaseDrawer>

      <BaseDrawer
        isOpen={createNewProductConfiguration.isOpen}
        onClose={createNewProductConfiguration.onClose}
        size="md"
        header={
          <>
            <Box p={3} mt={3}>
              <PageHeader
                center
                title="Create a new configuration"
                subtitle="Easy going!"
              />
            </Box>
          </>
        }
        placement="right"
        showCloseButton
      >
        <CreateNewProductConfigurationDrawerContent
          onSuccess={onSuccessfullyCreated}
        />
      </BaseDrawer>
      <BaseDrawer
        isOpen={updateProductConfiguration.isOpen}
        onClose={updateProductConfiguration.onClose}
        size="md"
        header={
          <>
            <Box p={3} mt={3}>
              <PageHeader
                center
                title="Update a configuration"
                subtitle="Easy going!"
              />
            </Box>
          </>
        }
        placement="right"
        showCloseButton
      >
        <UpdateProductConfigurationDrawerContent
          productConfiguration={selectedProductConfiguration}
          onSuccess={onSuccessfullyUpdated}
        />
      </BaseDrawer>
      <DeleteConfirmationModal
        isOpen={!!selectedProductConfigurationToDelete}
        onClose={() => {
          setSelectedProductConfigurationToDelete(undefined);
        }}
        onDelete={onDeleteProductConfiguration}
        description="All products and options associated with this configuration will have it removed."
      />
    </>
  );
};
