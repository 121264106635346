import { FormControl, FormErrorMessage, Switch, Text } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { CommonHookFormFieldProps } from "./Types";

export const HookSwitch = ({
  name,
  label,
  required,
  defaultChecked,
  readonly,
  key = "",
}: CommonHookFormFieldProps & {
  defaultChecked?: boolean;
  key?: string;
}) => {
  const methods = useFormContext();
  const error = methods.formState.errors[name];

  return (
    <FormControl isRequired={required} isInvalid={!!error}>
      <Switch
        key={key}
        defaultChecked={defaultChecked}
        readOnly={readonly}
        id={name}
        {...methods.register(name)}
      >
        {label}
      </Switch>
      <FormErrorMessage>
        <Text>{error && error.message?.toString()}</Text>
      </FormErrorMessage>
    </FormControl>
  );
};
