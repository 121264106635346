import { Text, Button, Center, HStack } from "@chakra-ui/react";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  handleNext: () => void;
  handlePrevious: () => void;
};

export const Pagination = ({
  currentPage,
  totalPages,
  handleNext,
  handlePrevious,
}: PaginationProps) => {
  return (
    <Center>
      <HStack
        justifyContent="space-between"
        width="full"
        px={[0, 0, 0, 20]}
        mt={6}
      >
        <Button
          aria-disabled={currentPage === 1}
          variant="outline"
          mr={2}
          onClick={handlePrevious}
          fontSize="sm"
        >
          Previous
        </Button>
        <Text fontSize="sm">
          Page {currentPage} of {totalPages}
        </Text>
        <Button
          aria-disabled={currentPage === totalPages}
          variant="outline"
          onClick={handleNext}
          fontSize="sm"
        >
          Next
        </Button>
      </HStack>
    </Center>
  );
};
