import {
  Avatar,
  Box,
  Button,
  HStack,
  Stack,
  Text,
  Icon,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { FilterInput } from "../../Components/Forms/FilterInputField";
import { ProductCategoryWithProducts } from "../../SWR/EntityHooks/UseProductCategory";
import debounce from "lodash.debounce";
import { useSearchProductsForCategory } from "../../SWR/EntityHooks/UseSearchProductsForCategory";
import { Product } from "../../SWR/Types/Product";
import { FaPlus } from "react-icons/fa";
import { apiClient } from "../../SWR/ApiClient";
import { ApiRoutes } from "../../SWR/ApiRoutes";

type AddProductToCategoryModalContentProps = {
  category?: ProductCategoryWithProducts;
  onAdded: () => void;
};

const ProductNotInProductCategory = ({
  product,
  isAddLoading,
  onAdd,
}: {
  product: Product;
  isAddLoading: boolean;
  onAdd: (product: Product) => void;
}) => {
  return (
    <Stack key={product.id} fontSize="sm" px="4" spacing="4" width="full">
      <Stack direction="row" justify="space-between" spacing="4" width="full">
        <HStack spacing="3" width="full">
          <Avatar
            src={
              product?.pictures?.length > 0
                ? product.pictures[0].full_picture_url
                : ""
            }
            boxSize="10"
          />
          <Box width="full">
            <HStack width="full" justifyContent="space-between">
              <Text fontWeight="medium" color="fg.emphasized">
                {product.name}
              </Text>
              <Button
                height="35px"
                width="50px"
                bg="green"
                isLoading={isAddLoading}
                onClick={() => onAdd(product)}
              >
                <Icon as={FaPlus} />
              </Button>
            </HStack>
          </Box>
        </HStack>
      </Stack>
    </Stack>
  );
};

export default function AddProductToCategoryModalContent({
  category,
  onAdded,
}: AddProductToCategoryModalContentProps) {
  const [search, setSearch] = useState("");
  const [addLoading, setAddLoading] = useState(false);

  const {
    data: availableProducts,
    isLoading: availableProductsLoading,
    mutate,
  } = useSearchProductsForCategory({
    search: search,
    productCategoryId: category?.id ?? 0,
  });

  const handleChanged = (e: string) => {
    setSearch(e);
  };

  const debouncedProductSearch = useMemo(() => {
    return debounce(handleChanged, 800);
  }, []);

  const onAdd = (product: Product) => {
    if (!category) {
      return;
    }

    setAddLoading(true);
    apiClient
      .put(ApiRoutes.ProductCategories.Products.Add, {
        product_id: product.id,
        product_category_id: category.id,
      })
      .then(() => {
        setAddLoading(false);
        mutate();
        onAdded();
      });
  };

  if (!category) {
    return <></>;
  }

  return (
    <>
      <HStack>
        <Box flexGrow={1}>
          <FilterInput
            name="product_name"
            label="Product Name"
            type="string"
            onChange={(value: string) => {
              debouncedProductSearch(value);
            }}
            required
            placeholder="Search product"
            showPlaceHolder
          />
        </Box>
        {availableProductsLoading && <Spinner />}
      </HStack>
      <VStack spacing={3} mt={3}>
        {availableProducts &&
          availableProducts.map((e) => {
            return (
              <ProductNotInProductCategory
                product={e}
                key={e.id}
                onAdd={onAdd}
                isAddLoading={addLoading}
              />
            );
          })}
      </VStack>
    </>
  );
}
