import useSWR from "swr";
import { PaginatedResponse } from "../Types/PaginatedResponse";
import { Order } from "../Types/Order";

export type UseOrdersIndexProps = {
  page: number;
  perPage: number;
};

export const useOrdersIndex = (
  props: UseOrdersIndexProps = { page: 1, perPage: 10 },
) => {
  const { data, error, mutate } = useSWR<PaginatedResponse<Order>>(
    `/orders?page=${props.page}&per_page=${props.perPage}`,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  );

  // Cache Call
  useSWR<PaginatedResponse<Order>>(
    `/orders?page=${props.page + 1}&per_page=${props.perPage}`,
  );

  const isLoading = !data && !error;

  return {
    data,
    isLoading,
    error,
    mutate,
  };
};
